import { Create, useForm } from "@refinedev/antd";
import { CanAccess, useParsed, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { Row, Col, Form } from "antd";
import { Preview, Data } from "../../components/file";
import { FILE_CREATE_MUTATION } from "../../queries";

type Props = {
  patient?: string;
};

export const FileCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps } = useForm({
    resource: "file",
    meta : {
      gqlMutation : FILE_CREATE_MUTATION,
    }
  });

  const { params } = useParsed<Props>();

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Create
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        title={translate("pages.file.titles.create")}
      >
        <Form
          {...formProps}
          initialValues={{
            file : {},
            patient: params?.patient ? JSON.parse(params.patient) : undefined,
          }}
        >
          <Row>
            <Col span={12}>
              <Preview editable />
            </Col>
            <Col span={12}>
              <Data editable />
            </Col>
          </Row>
        </Form>
      </Create>
    </CanAccess>
  );
};
