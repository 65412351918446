import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  SaveButton,
  TextField,
  useEditableTable,
} from "@refinedev/antd";
import { Button, Form, Space, Table } from "antd";
import {
  USERS_LIST_QUERY,
  USER_CREATE_MUTATION,
  USER_DELETE_MUTATION,
  USER_EDIT_MUTATION,
} from "../../../queries";
import { CanAccess, useInvalidate, useTranslate } from "@refinedev/core";
import { Input, Password, Select } from "antx";
import { Unauthorized } from "../../../components/common";
import { useState } from "react";
import { UserCreate } from "../../../components/user";

export const UsersList = () => {
  const translate = useTranslate();
  const invalidate = useInvalidate();

  const [openCreate, setOpenCreate] = useState(false);

  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useEditableTable({
    resource: "users",
    meta: {
      gqlMutation: USER_EDIT_MUTATION,
      gqlQuery: USERS_LIST_QUERY,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <List
        breadcrumb={false}
        canCreate
        createButtonProps={{ onClick: () => setOpenCreate(true) }}
      >
        <Form {...formProps}>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="id"
              title={translate("pages.user.fields.id")}
              width="5%"
            />
            <Table.Column
              dataIndex="createdAt"
              title={translate("pages.user.fields.created_at")}
              render={(value) => <DateField value={value} locales="de-DE" />}
              width="10%"
            />
            <Table.Column
              dataIndex="updatedAt"
              title={translate("pages.user.fields.created_at")}
              render={(value) => <DateField value={value} locales="de-DE" />}
              width="10%"
            />
            <Table.Column
              dataIndex="email"
              title={translate("pages.user.fields.email")}
              render={(value, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Input
                      name="email"
                      initialValue={value}
                      style={{ margin: 0 }}
                    />
                  );
                }
                return <TextField value={value} />;
              }}
              width="20%"
            />
            <Table.Column
              dataIndex="name"
              title={translate("pages.user.fields.name")}
              render={(value, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Input
                      name="name"
                      initialValue={value}
                      style={{ margin: 0 }}
                    />
                  );
                }
                return <TextField value={value} />;
              }}
              width="20%"
            />
            <Table.Column
              dataIndex="password"
              title={translate("pages.user.fields.password")}
              render={(value, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Password
                      name="password"
                      initialValue={undefined}
                      style={{ margin: 0 }}
                    />
                  );
                }
                return <TextField value="n.A." />;
              }}
              width="20%"
            />
            <Table.Column
              dataIndex="role"
              title={translate("selects.role.title")}
              render={(value, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Select
                      name="role"
                      initialValue={value}
                      style={{ margin: 0 }}
                      options={[
                        {
                          value: "GUEST",
                          label: translate("selects.role.guest"),
                        },
                        {
                          value: "USER",
                          label: translate("selects.role.user"),
                        },
                        {
                          value: "ADMIN",
                          label: translate("selects.role.admin"),
                        },
                      ]}
                    />
                  );
                }
                return (
                  <TextField
                    value={translate(
                      "selects.role." + String(value).toLowerCase()
                    )}
                  />
                );
              }}
              width="20%"
            />
            <Table.Column
              title={translate("table.actions")}
              dataIndex="actions"
              width="5%"
              render={(_, record: any) => {
                if (isEditing(record.id)) {
                  return (
                    <Space>
                      <SaveButton {...saveButtonProps} hideText />
                      <DeleteButton
                        recordItemId={record.id}
                        meta={{ gqlMutation: USER_DELETE_MUTATION }}
                        hideText
                        onSuccess={() =>
                          invalidate({
                            resource: "users",
                            invalidates: ["list"],
                          })
                        }
                      />
                      <Button {...cancelButtonProps}>
                        {translate("buttons.cancel")}
                      </Button>
                    </Space>
                  );
                }
                return <EditButton {...editButtonProps(record.id)} hideText />;
              }}
            />
          </Table>
        </Form>
      </List>
      <UserCreate open={openCreate} onClose={() => setOpenCreate(false)} />
    </CanAccess>
  );
};
