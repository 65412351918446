import type { AuthProvider } from "@refinedev/core";
import { gqlClient } from "./data-provider";
import { identity } from ".";
import {
  AUTH_LOGIN_MUTATION,
  AUTH_LOGOUT_MUTATION,
  AUTH_REGISTER_MUTATION,
} from "../queries";
import { ILogin, IUser } from "../types/auth";

export const TOKEN_KEY = "refine-auth";

const sendRequest = async <T>(mutation: any, options?: any) => {
  return gqlClient.request<T>(mutation, options);
};

export const authProvider: AuthProvider = {
  register: async ({ email, password }) => {
    try {
      if (!email || !password) {
        return {
          success: false,
          error: {
            name: "Register error",
            message: "Invalid username or password",
          },
        };
      }

      let response = await sendRequest<{
        createUser: IUser;
      }>(AUTH_REGISTER_MUTATION, {
        input: {
          email,
          password,
        },
      });

      if (!response.createUser.id || response.createUser.id <= 0) {
        return {
          success: false,
          error: {
            name: "Register error",
            message: "Invalid username or password",
          },
        };
      }

      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (e: any) {
      return {
        success: false,
        error: {
          name: "Register error",
          message: e.message,
        },
      };
    }
  },
  login: async ({ email, password }) => {
    try {
      if (!email || !password) {
        return {
          success: false,
          error: {
            name: "Login error",
            message: "Invalid username or password",
          },
        };
      }

      let response = await sendRequest<{
        signInUser?: ILogin;
      }>(AUTH_LOGIN_MUTATION, {
        input: {
          email,
          password,
        },
      });

      if (
        !response.signInUser?.access ||
        !response.signInUser?.refresh ||
        !response.signInUser?.user
      ) {
        return {
          success: false,
          error: {
            name: "Login error",
            message: "No information received",
          },
        };
      }

      identity.setData(
        response.signInUser.user,
        response.signInUser.refresh,
        response.signInUser.access
      );

      return {
        success: true,
        redirectTo: "/",
      };
    } catch (e: any) {
      return {
        success: false,
        error: {
          name: "Login error",
          message: e.message,
        },
      };
    }
  },
  logout: async () => {
    try {
      if (!identity.hasRefreshToken() && !identity.isRefreshTokenValid()) {
        identity.clearData();
        return {
          success: true,
          redirectTo: "/login",
        };
      }

      let refresh = identity.refreshToken();
      let user = identity.identity();

      await sendRequest<boolean>(AUTH_LOGOUT_MUTATION, {
        input: {
          email: user.email,
          refresh: refresh.token,
        },
      });
      identity.clearData();

      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (err: any) {
      identity.clearData();
      return {
        success: true,
        redirectTo: "/login",
      };
    }
  },
  check: async () => {
    try {
      if (identity.hasAccessToken() && identity.isAccessTokenValid()) {
        if (!identity.hasAuthHeaderSet()) {
          identity.setAuthHeader();
        }
        return {
          authenticated: true,
        };
      }

      if (
        identity.hasRefreshToken() &&
        identity.isRefreshTokenValid() &&
        (await identity.refresh())
      ) {
        if (identity.hasAccessToken() && identity.isAccessTokenValid()) {
          return {
            authenticated: true,
          };
        }
      }

      return {
        authenticated: false,
        redirectTo: "/login",
      };
    } catch (e: any) {
      return {
        authenticated: false,
        redirectTo: "/login",
      };
    }
  },
  getPermissions: async () => identity.permission(),
  getIdentity: async () => identity.identity(),
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
