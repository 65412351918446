import { DateField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import dayjs from "dayjs";
import { CSSProperties } from "react";
import { HeaderText } from "../common";

type Props = {
  createdAt?: Date;
  style?: CSSProperties;
};

export const Created = ({ createdAt, style }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText
      title={translate("pages.treatment.fields.createdAt")}
      style={style}
    >
      <DateField value={dayjs(createdAt).locale("de-de")} />
    </HeaderText>
  );
};
