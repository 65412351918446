import { BaseRecord, useTranslate } from "@refinedev/core";
import { Col, DatePicker, Divider, Form, Row } from "antd";
import { DateField, TextField } from "@refinedev/antd";
import { Input, Watch } from "antx";
import { HeaderText } from "../common";
import { Address } from ".";
import dayjs from "dayjs";
import locale from "antd/locale/de_DE";

type Props = {
  record?: BaseRecord;
  editable?: boolean;
};

export const General = ({ record, editable }: Props) => {
  const translate = useTranslate();

  return (
    <>
      <Col span={24}>
        <Divider>{translate("titles.general")}</Divider>
        <Row>
          <Col span={4}>
            <HeaderText title={translate("pages.patient.fields.id")}>
              {editable ? (
                <Watch name="id">{(id) => <TextField value={id ?? 0} />}</Watch>
              ) : (
                <TextField value={record?.id ?? 0} />
              )}
            </HeaderText>
          </Col>
          <Col span={6}>
            <HeaderText title={translate("pages.patient.fields.salutation")}>
              {editable ? (
                <Input name="salutation" style={{ margin: "-5px" }} />
              ) : (
                <TextField value={record?.salutation ?? "n.A."} />
              )}
            </HeaderText>
          </Col>
          <Col span={8}>
            <HeaderText title={translate("pages.patient.fields.name")}>
              {editable ? (
                <Input
                  name="name"
                  style={{ margin: "-5px" }}
                  rules={[
                    {
                      required: true,
                      message: translate("validations.empty_name"),
                    },
                  ]}
                />
              ) : (
                <TextField value={record?.name ?? "n.A."} />
              )}
            </HeaderText>
          </Col>
          <Col span={6}>
            <HeaderText title={translate("pages.patient.fields.birthdate")}>
              {editable ? (
                <Form.Item
                  style={{ margin: "-5px" }}
                  name="birthdate"
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : null,
                  })}
                  getValueFromEvent={(value) =>
                    value ? dayjs(value).format("YYYY-MM-DD") : null
                  }
                  rules={[
                    {
                      required: true,
                      message: translate("validations.empty_birthdate"),
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    locale={locale.DatePicker}
                    format={"DD.MM.YYYY"}
                  />
                </Form.Item>
              ) : (
                <TextField
                  value={
                    record?.birthdate === undefined ||
                    record?.birthdate === null
                      ? "n.A"
                      : dayjs(record.birthdate).format("L")
                  }
                />
              )}
            </HeaderText>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Row>
              <Col span={24}>
                <HeaderText title={translate("pages.patient.fields.createdAt")}>
                  <DateField value={dayjs(record?.createdAt).locale("de-de")} />
                </HeaderText>
              </Col>
              <Col span={24}>
                <HeaderText title={translate("pages.patient.fields.updatedAt")}>
                  <DateField value={dayjs(record?.updatedAt).locale("de-de")} />
                </HeaderText>
              </Col>
            </Row>
          </Col>
          <Col span={20}>
            <Address address={record?.address} editable={editable} />
          </Col>
        </Row>
      </Col>
    </>
  );
}
