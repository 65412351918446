import { TextField } from "@refinedev/antd";
import { useSelect, useTranslate } from "@refinedev/core";
import { Button, Divider, Input, InputRef, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { Select } from "antx";
import { PlusOutlined } from "@ant-design/icons";
import { HeaderText } from "../common";
import { SERVICE_BLOCK_LIST } from "../../queries";

type Props = {
  editable?: boolean;
  record?: any;
};

export const Block = ({ editable, record }: Props) => {
  const translate = useTranslate();

  const { options } = useSelect({
    resource: "serviceBlocks",
    optionLabel: "serviceBlock",
    optionValue: "serviceBlock",
    meta: {
      gqlQuery: SERVICE_BLOCK_LIST,
    },
  });

  const [items, setItems] = useState(options);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems((prev) => [...prev, { value: name, label: name }]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    setItems(options);
  }, [options]);

  return (
    <HeaderText title={translate("pages.service.fields.service_block")}>
      {editable ? (
        <Select
          name="serviceBlock"
          rules={[
            {
              required: true,
              message: translate("validations.empty_service_block"),
            },
          ]}
          options={items}
          popupMatchSelectWidth={false}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space.Compact style={{ padding: "0 8px 4px", width: "100%" }}>
                <Input
                  placeholder={translate("pages.service.placeholders.name")}
                  ref={inputRef}
                  value={name}
                  onChange={onNameChange}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                  {translate("buttons.add")}
                </Button>
              </Space.Compact>
            </>
          )}
        />
      ) : (
        <TextField value={record?.serviceBlock ?? "n.A."} />
      )}
    </HeaderText>
  );
};
