import { ProfileOutlined } from "@ant-design/icons";
import { Route } from "react-router-dom";
import { ReceiptsList } from "./list";
import { ReceiptCreate } from "./create";
import { ReceiptEdit } from "./edit";
import { ReceiptShow } from "./show";
import { CanAccess } from "@refinedev/core";
import { Unauthorized } from "../../components/common";

export const ReceiptRoute = () => (
  <Route path="/receipt">
    <Route
      index
      element={
        <CanAccess fallback={<Unauthorized />}>
          <ReceiptsList />
        </CanAccess>
      }
    />
    <Route
      path="create/:patient?"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <ReceiptCreate />
        </CanAccess>
      }
    />
    <Route
      path="edit/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <ReceiptEdit />
        </CanAccess>
      }
    />
    <Route
      path="show/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <ReceiptShow />
        </CanAccess>
      }
    />
  </Route>
);

export const ReceiptProps = {
  name: "receipt",
  list: "/receipt",
  create: "/receipt/create",
  edit: "/receipt/edit/:id",
  show: "/receipt/show/:id",
  meta: {
    canDelete: true,
    icon: <ProfileOutlined />,
  },
};
