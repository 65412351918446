import { useTranslate } from "@refinedev/core";
import { Button, Card, Flex, Form, List, Typography } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Select } from "antx";
import { EmptyData, HeaderText } from "../common";

type Props = {
  editable?: boolean;
  address?: any;
};

export const Address = ({ editable, address }: Props) => {
  const translate = useTranslate();

  const renderShowAddress = () => (
    <List
      style={{ marginTop: "-10px" }}
      grid={{ column: 4, gutter: [8, 8] }}
      dataSource={address}
      renderItem={(item: any) => (
        <Card
          key={item.id}
          size="small"
          type="inner"
          style={{ marginRight: "5px", marginLeft: "5px" }}
          title={translate(
            "selects.address." + String(item.type).toLowerCase()
          )}
        >
          <Flex vertical>
            <Typography.Text>
              {item.name === null ? "n.A." : item.name}
            </Typography.Text>
            <Typography.Text>
              {item.street === null ? "n.A." : item.street}
            </Typography.Text>
            <Typography.Text>
              {(item.postcode || item.city) === null
                ? "n.A."
                : item.postcode + " " + item.city}
            </Typography.Text>
            <Typography.Text>
              {item.country === null ? "n.A." : item.country}
            </Typography.Text>
          </Flex>
        </Card>
      )}
    />
  );

  const renderEditAddress = () => (
    <Form.List name="address">
      {(fields, { add, remove }) => (
        <>
          {fields.length > 0 ? (
            <List
              locale={{ emptyText: <div /> }}
              dataSource={fields}
              grid={{ column: 4, gutter: [8, 8] }}
              renderItem={({ key, name, ...restField }) => (
                <Card
                  size="small"
                  type="inner"
                  key={key}
                  style={{ marginRight: "5px", marginLeft: "5px" }}
                  extra={
                    fields.length > 1 && (
                      <CloseOutlined
                        style={{ paddingLeft: "10px" }}
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    )
                  }
                  title={
                    <Select
                      name={[name, "type"]}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <Select.Option value="HOME">
                        {translate("selects.address.home")}
                      </Select.Option>
                      <Select.Option value="INVOICE">
                        {translate("selects.address.invoice")}
                      </Select.Option>
                      <Select.Option value="COMPANY">
                        {translate("selects.address.company")}
                      </Select.Option>
                    </Select>
                  }
                >
                  <Flex vertical gap={5}>
                    <Input
                      placeholder={translate("pages.patient.fields.name")}
                      name={[name, "name"]}
                      style={{ margin: "0px" }}
                      rules={[
                        {
                          required: true,
                          message: translate("validations.empty_name"),
                        },
                      ]}
                    />
                    <Input
                      placeholder={translate(
                        "pages.patient.fields.address.street"
                      )}
                      name={[name, "street"]}
                      style={{ margin: "0px" }}
                      rules={[
                        {
                          required: true,
                          message: translate("validations.empty_street"),
                        },
                      ]}
                    />
                    <Flex gap={5}>
                      <Input
                        placeholder={translate(
                          "pages.patient.fields.address.postcode"
                        )}
                        name={[name, "postcode"]}
                        style={{ margin: "0px" }}
                        rules={[
                          {
                            required: true,
                            message: translate("validations.empty_postcode"),
                          },
                        ]}
                      />
                      <Input
                        placeholder={translate(
                          "pages.patient.fields.address.city"
                        )}
                        name={[name, "city"]}
                        style={{ margin: "0px" }}
                        rules={[
                          {
                            required: true,
                            message: translate("validations.empty_city"),
                          },
                        ]}
                      />
                    </Flex>
                    <Input
                      placeholder={translate(
                        "pages.patient.fields.address.country"
                      )}
                      name={[name, "country"]}
                      style={{ margin: "0px" }}
                      rules={[
                        {
                          required: true,
                          message: translate("validations.empty_country"),
                        },
                      ]}
                    />
                  </Flex>
                </Card>
              )}
            />
          ) : (
            <EmptyData text={translate("empty_data")} height={80} />
          )}
          <Form.Item style={{ margin: "0px", paddingTop: "10px" }}>
            <Button
              type="dashed"
              onClick={() => {
                add({
                  default: false,
                  type: "HOME",
                });
              }}
              block
            >
              <PlusOutlined /> {translate("buttons.add")}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );

  return (
    <HeaderText title={translate("pages.patient.fields.address.title")}>
      {editable ? renderEditAddress() : renderShowAddress()}
    </HeaderText>
  );
};
