import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { TextArea } from "antx";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  record?: any;
};

export const Description = ({ editable, record }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText title={translate("pages.service.fields.description")}>
      {editable ? (
        <TextArea name="description" style={{ margin: 0 }} />
      ) : (
        <TextField
          value={record?.description ?? "n.A."}
          style={{ minHeight: 50 }}
        />
      )}
    </HeaderText>
  );
};
