import { DateField, List, TextField, useTable } from "@refinedev/antd";
import {
  CanAccess,
  CrudFilters,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Table } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Unauthorized } from "../../components/common";
import { RECEIPTS_LIST_QUERY } from "../../queries";

export const ReceiptsList = () => {
  const [filters, setFilters] = useState<CrudFilters>([]);

  const translate = useTranslate();
  const { show } = useNavigation();
  const { tableProps } = useTable({
    resource: "receipts",
    syncWithLocation: true,
    meta: {
      gqlQuery: RECEIPTS_LIST_QUERY,
    },
    filters: {
      permanent: filters,
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
  });

  const truncateString = (str: string, num: number) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  return (
    <CanAccess fallback={<Unauthorized />}>
      <List
        headerButtons={({ defaultButtons }) => (
          <>
            <Search
              allowClear
              placeholder={translate("pages.receipt.placeholders.search")}
              onSearch={(value) =>
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value,
                  },
                ])
              }
            />
            {defaultButtons}
          </>
        )}
      >
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record, _) => {
            return {
              onClick: () => record.id && show("receipt", record.id),
            };
          }}
        >
          <Table.Column
            dataIndex="reference"
            title={translate("pages.receipt.fields.reference")}
            width="10%"
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            title={translate("pages.receipt.fields.createdAt")}
            render={(value) => <DateField value={value} locales="de-DE" />}
            width="10%"
            sorter
          />
          <Table.Column
            dataIndex={["patient", "name"]}
            title={translate("pages.receipt.fields.patientName")}
            width="15%"
            sorter
          />
          <Table.Column
            dataIndex="content"
            title={translate("pages.receipt.fields.content")}
            render={(value: any) => (
              <TextField value={truncateString(String(value ?? "n.A."), 150)} />
            )}
            sorter
          />
        </Table>
      </List>
    </CanAccess>
  );
};
