import { Edit, useForm } from "@refinedev/antd";
import { CanAccess, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { Form } from "antd";
import { Info } from "../../components/receipt/info";
import { Name } from "../../components/receipt/name";
import { Content } from "../../components/receipt";
import {
  RECEIPT_DELETE_MUTATION,
  RECEIPT_SHOW_QUERY,
  RECEIPT_UPDATE_MUTATION,
} from "../../queries";

export const ReceiptEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, formLoading } = useForm({
    meta: {
      gqlQuery: RECEIPT_SHOW_QUERY,
      gqlMutation: RECEIPT_UPDATE_MUTATION,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Edit
        saveButtonProps={saveButtonProps}
        deleteButtonProps={{ meta: { gqlMutation: RECEIPT_DELETE_MUTATION } }}
        isLoading={formLoading}
        breadcrumb={false}
        title={translate("pages.receipt.titles.edit")}
      >
        <Form {...formProps}>
          <Info editable />
          <Name editable />
          <Content editable />
        </Form>
      </Edit>
    </CanAccess>
  );
};
