import { Route } from "react-router-dom";
import { UsersList } from ".";
import { TeamOutlined } from "@ant-design/icons";
import { CanAccess } from "@refinedev/core";
import { Unauthorized } from "../../../components/common";

export const UserRoute = () => (
  <Route
    path="user"
    element={
      <CanAccess fallback={<Unauthorized />}>
        <UsersList />
      </CanAccess>
    }
  />
);

export const UserProps = {
  name: "user",
  meta: {
    parent: "setting",
    icon: <TeamOutlined />,
  },
  list: "/setting/user",
};
