import gql from "graphql-tag";

export const TREATMENTS_LIST_QUERY = gql`
  query Treatments(
    $paging: PagingInput
    $filter: FilterInput
    $sorting: [SorterInput!]
  ) {
    treatments(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        updatedAt
        status
        reference
        patient {
          name
        }
      }
      totalCount
    }
  }
`;

export const TREATMENT_SHOW_QUERY = gql`
  query Treatment($id: ID!) {
    treatment(id: $id) {
      id
      createdAt
      updatedAt
      status
      reference
      completedOn
      results
      treatment
      patient {
        name
      }
      diagnoses {
        id
        description
        code
      }
      positions {
        id
        serviceName
        quantity
        description
      }
    }
  }
`;

export const TREATMENT_CREATE_MUTATION = gql`
  mutation CreateTreatment($input: CreateTreatmentInput!) {
    createTreatment(input: $input) {
      status
      reference
      completedOn
      results
      treatment
      patient {
        id
        name
      }
      diagnoses {
        description
        code
        icdEntry {
          id
        }
      }
      positions {
        service {
          id
        }
        serviceName
        quantity
        description
      }
    }
  }
`;

export const TREATMENT_EDIT_QUERY = gql`
  query Treatment($id: ID!) {
    treatment(id: $id) {
      id
      createdAt
      updatedAt
      status
      reference
      completedOn
      results
      treatment
      patient {
        id
        name
      }
      diagnoses {
        description
        code
        icdEntry {
          id
        }
      }
      positions {
        id
        service {
          id
          name
          ownValue
          feeThreshold
          feeMin
          feeMax
          bayernValue
          bundValue
        }
        serviceName
        quantity
        description
      }
    }
  }
`;

export const TREATMENT_EDIT_MUTATION = gql`
  mutation UpdateTreatment($input: UpdateTreatmentInput!) {
    updateTreatment(input: $input) {
      id
      createdAt
      updatedAt
      status
      reference
      completedOn
      results
      treatment
      patient {
        id
        name
      }
      diagnoses {
        id
        description
        code
        icdEntry {
          id
        }
      }
      positions {
        id
        service {
          id
          name
          ownValue
          feeThreshold
          feeMin
          feeMax
          bayernValue
          bundValue
        }
        serviceName
        quantity
        description
      }
    }
  }
`;

export const TREATMENT_DELETE_MUTATION = gql`
  mutation DeleteTreatment($input: DeleteTreatmentInput!) {
    deleteTreatment(input: $input) {
      id
    }
  }
`;
