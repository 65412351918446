import { BaseRecord, useTranslate } from "@refinedev/core";
import { Col, Divider, Row } from "antd";
import { Address, Completed, Created, Name, Reference, Status } from ".";

type Props = {
  record?: BaseRecord;
  editable?: boolean;
  create?: boolean;
};

export const General = ({ record, editable, create }: Props) => {
  const translate = useTranslate();
  const span = create ? 12 : 8;

  return (
    <>
      <Divider>{translate("pages.invoice.fields.general")}</Divider>
      <Row style={{ marginTop: "-10px" }}>
        {!create && (
          <Col span={span}>
            <Reference reference={record?.reference} editable={editable} />
          </Col>
        )}
        <Col span={span}>
          <Name name={record?.patient?.name} editable={editable} />
        </Col>
        <Col span={span}>
          <Status status={record?.status} editable={editable} />
        </Col>
      </Row>
      <Row>
        {!create && (
          <Col span={span}>
            <Created createdAt={record?.createdAt} />
          </Col>
        )}
        <Col span={span}>
          <Address address={record?.address} editable={editable} />
        </Col>
        <Col span={span}>
          <Completed completedOn={record?.completedOn} editable={editable} />
        </Col>
      </Row>
    </>
  );
};
