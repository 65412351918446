import gql from "graphql-tag";

export const RECEIPTS_LIST_QUERY = gql`
  query Receipts(
    $paging: PagingInput
    $filter: FilterInput
    $sorting: [SorterInput!]
  ) {
    receipts(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        reference
        createdAt
        content
        patient {
          id
          name
        }
      }
      totalCount
    }
  }
`;

export const RECEIPT_SHOW_QUERY = gql`
  query Receipt($id: ID!) {
    receipt(id: $id) {
      id
      createdAt
      updatedAt
      reference
      content
      patient {
        id
        name
      }
    }
  }
`;

export const RECEIPT_CREATE_MUTATION = gql`
  mutation CreateReceipt($input: CreateReceiptInput!) {
    createReceipt(input: $input) {
      id
      createdAt
      updatedAt
      reference
      content
    }
  }
`;

export const RECEIPT_UPDATE_MUTATION = gql`
  mutation UpdateReceipt($input: UpdateReceiptInput!) {
    updateReceipt(input: $input) {
      id
      createdAt
      updatedAt
      reference
      content
    }
  }
`;

export const RECEIPT_DELETE_MUTATION = gql`
  mutation DeleteReceipt($input: DeleteReceiptInput!) {
    deleteReceipt(input: $input) {
      id
    }
  }
`;

export const RECEIPT_TEMPLATES_QUERY = gql`
  query ReceiptTemplates {
    receiptTemplates {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        createdAt
        updatedAt
        name
        content
      }
    }
  }
`;

export const RECEIPT_TEMPLATE_SHOW_QUERY = gql`
  query ReceiptTemplate($id: ID!) {
    receiptTemplate(id: $id) {
      id
      createdAt
      updatedAt
      name
      content
    }
  }
`;

export const RECEIPT_TEMPLATE_CREATE_MUTATION = gql`
  mutation CreateReceiptTemplate($input: CreateReceiptTemplateInput!) {
    createReceiptTemplate(input: $input) {
      id
      createdAt
      updatedAt
      reference
      content
    }
  }
`;

export const RECEIPT_TEMPLATE_UPDATE_MUTATION = gql`
  mutation UpdateReceiptTemplate($input: UpdateReceiptTemplateInput!) {
    updateReceiptTemplate(input: $input) {
      id
      createdAt
      updatedAt
      reference
      content
    }
  }
`;

export const RECEIPT_TEMPLATE_DELETE_MUTATION = gql`
  mutation DeleteReceiptTemplate($input: DeleteReceiptTemplateInput!) {
    deleteReceiptTemplate(input: $input) {
      id
      createdAt
      updatedAt
      reference
      content
    }
  }
`;
