import { Edit, useForm } from "@refinedev/antd";
import { Form, Row } from "antd";
import {
  PATIENT_DELETE_MUTATION,
  PATIENT_EDIT_MUTATION,
} from "../../queries";
import { CanAccess, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { General, Contact, Insurance } from "../../components/patient";

export const PatientEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, formLoading } = useForm({
    meta: {
      gqlMutation: PATIENT_EDIT_MUTATION,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
    <Edit
      saveButtonProps={saveButtonProps}
      deleteButtonProps={{ meta: { gqlMutation: PATIENT_DELETE_MUTATION } }}
      isLoading={formLoading}
      breadcrumb={false}
      title={translate("pages.patient.titles.edit")}
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={20}>
          <General editable />
          <Contact editable />
          <Insurance editable />
        </Row>
      </Form>
    </Edit>
    </CanAccess>
  );
};
