import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { DatePicker, Form } from "antd";
import locale from "antd/locale/de_DE";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  completedOn?: Date;
};

export const Completed = ({ editable, completedOn }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText title={translate("pages.invoice.fields.completedOn")}>
      {editable ? (
        <Form.Item
          style={{ margin: "-5px" }}
          name="completedOn"
          getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
          getValueFromEvent={(value) => (value ? dayjs(value).toDate() : null)}
        >
          <DatePicker
            style={{ width: "100%" }}
            locale={locale.DatePicker}
            format={"DD.MM.YYYY"}
          />
        </Form.Item>
      ) : (
        <TextField
          value={
            completedOn === null || completedOn === undefined
              ? "n.A"
              : dayjs(completedOn).format("L")
          }
        />
      )}
    </HeaderText>
  );
};
