import { FormInstance } from "antd";
import { NamePath } from "antd/es/form/interface";

export const updateFieldValue = <Values = any>(
  form: FormInstance<Values>,
  name: NamePath,
  value: any
) => {
  (form as any).getInternalHooks("RC_FORM_INTERNAL_HOOKS").dispatch({
    type: "updateValue",
    namePath: name,
    value: value,
  });
};
