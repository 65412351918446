import { Dropdown, Flex, Form, Input, Space, Typography } from "antd";
import { HeaderText, updateFieldValue } from "../common";
import { useList, useTranslate } from "@refinedev/core";
import { TextArea } from "antx";
import { DownOutlined } from "@ant-design/icons";
import { RECEIPT_TEMPLATES_QUERY } from "../../queries";
import type { MenuProps } from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

type Props = {
  content?: string;
  editable?: boolean;
};

export const Content = ({ content, editable }: Props) => {
  const translate = useTranslate();
  const form = useFormInstance();

  const { data } = useList({
    resource: "receiptTemplates",
    meta: {
      gqlQuery: RECEIPT_TEMPLATES_QUERY,
    },
  });

  const templates =
    data?.data?.map((x) => ({ label: x.name, key: x.content })) ?? [];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    updateFieldValue(form, "content", key);
  };

  return (
    <HeaderText title={translate("pages.receipt.fields.content")}>
      {editable ? (
        <Flex gap={10} vertical>
          <Dropdown
            menu={{
              items: templates,
              onClick,
            }}
          >
            <a
              style={{ padding: 5, alignSelf: "end" }}
              onClick={(e) => e.preventDefault()}
            >
              <Space>
                <DownOutlined />
                {translate("pages.receipt.fields.select_template")}
              </Space>
            </a>
          </Dropdown>
          <TextArea
            name="content"
            autoSize={{ minRows: 10 }}
            style={{ margin: 0 }}
          />
        </Flex>
      ) : (
        <Typography.Paragraph
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {content}
        </Typography.Paragraph>
      )}
    </HeaderText>
  );
};
