import gql from "graphql-tag";

export const PATIENTS_SEARCH_QUERY = gql`
  query Patients($filter: FilterInput) {
    patients(filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`;

export const PATIENTS_LIST_QUERY = gql`
  query Patients(
    $paging: PagingInput
    $filter: FilterInput
    $sorting: [SorterInput!]
  ) {
    patients(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        updatedAt
        salutation
        name
        birthdate
      }
      totalCount
    }
  }
`;

export const PATIENT_ADDRESS_QUERY = gql`
  query Patient($id: ID!) {
    patient(id: $id) {
      id
      name
    }
  }
`;

export const PATIENT_SHOW_QUERY = gql`
  query Patient($id: ID!) {
    patient(id: $id) {
      id
      createdAt
      updatedAt
      salutation
      name
      birthdate
      email
      telPrivate
      telPublic
      insuranceName
      insuranceNumber
      address {
        id
        createdAt
        updatedAt
        name
        street
        postcode
        city
        country
        type
        default
      }
    }
  }
`;

export const PATIENT_CREATE_MUTATION = gql`
  mutation CreatePatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      salutation
      name
      birthdate
      email
      telPrivate
      telPublic
      insuranceName
      insuranceNumber
      address {
        id
        name
        street
        postcode
        city
        country
        type
        default
      }
    }
  }
`;

export const PATIENT_EDIT_MUTATION = gql`
  mutation UpdatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      id
      createdAt
      updatedAt
      salutation
      name
      birthdate
      email
      telPrivate
      telPublic
      insuranceName
      insuranceNumber
      address {
        id
        name
        street
        postcode
        city
        country
        type
        default
      }
    }
  }
`;

export const PATIENT_DELETE_MUTATION = gql`
  mutation DeletePatient($input: DeletePatientInput!) {
    deletePatient(input: $input) {
      id
    }
  }
`;
