import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "./App";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://adc4ace45b9f4ff5f0740e15cbb7c1d2@sentry.bloomers-berlin.de/7",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
