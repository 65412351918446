import gql from "graphql-tag";

export const SERVICE_SEARCH_QUERY = gql`
  query Services($paging: PagingInput!, $filter: FilterInput) {
    services(paging: $paging, filter: $filter) {
      nodes {
        id
        name
        ownValue
        feeThreshold
        feeMin
        feeMax
        bayernValue
        bundValue
      }
    }
  }
`;

export const SERVICE_SELECT_QUERY = gql`
  query Service($id: ID!) {
    service(id: $id) {
      id
      name
      ownValue
      feeThreshold
      feeMin
      feeMax
      bayernValue
      bundValue
    }
  }
`;

export const SERVICES_LIST_QUERY = gql`
  query Services($paging: PagingInput!, $filter: FilterInput) {
    services(paging: $paging, filter: $filter) {
      totalCount
      nodes {
        id
        createdAt
        updatedAt
        name
        feeThreshold
        feeId
        active
        reference
        serviceType
        serviceBlock
        description
        ownValue
        bayernValue
        bundValue
        feeMin
        feeMax
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const SERVICE_DELETE_MUTATION = gql`
  mutation DeleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      id
    }
  }
`;

export const SERVICE_SHOW_QUERY = gql`
  query Service($id: ID!) {
    service(id: $id) {
      id
      createdAt
      updatedAt
      name
      feeThreshold
      feeId
      active
      reference
      serviceType
      serviceBlock
      description
      ownValue
      bayernValue
      bundValue
      feeMin
      feeMax
    }
  }
`;

export const SERVICE_EDIT_MUTATION = gql`
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      id
      createdAt
      updatedAt
      name
      feeThreshold
      feeId
      active
      reference
      serviceType
      serviceBlock
      description
      ownValue
      bayernValue
      bundValue
      feeMin
      feeMax
    }
  }
`;

export const SERVICE_CREATE_MUTATION = gql`
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      id
      createdAt
      updatedAt
      name
      feeThreshold
      feeId
      active
      reference
      serviceType
      serviceBlock
      description
      ownValue
      bayernValue
      bundValue
      feeMin
      feeMax
    }
  }
`;

export const SERVICE_BLOCK_LIST = gql`
  query ServiceBlocks {
    serviceBlocks {
      totalCount
      nodes {
        serviceBlock
      }
    }
  }
`;

export const SERVICE_TYPE_LIST = gql`
  query ServiceTypes {
    serviceTypes {
      totalCount
      nodes {
        serviceType
      }
    }
  }
`;