import { List, useTable } from "@refinedev/antd";
import {
  CanAccess,
  CrudFilters,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Table } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { SERVICES_LIST_QUERY } from "../../../queries";
import { Unauthorized } from "../../../components/common";

export const ServicesList = () => {
  const [filters, setFilters] = useState<CrudFilters>([]);

  const translate = useTranslate();
  const { show } = useNavigation();
  const { tableProps } = useTable({
    resource: "services",
    syncWithLocation: true,
    meta: {
      gqlQuery: SERVICES_LIST_QUERY,
    },
    filters: {
      permanent: filters,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <List
        breadcrumb={false}
        headerButtons={({ defaultButtons }) => (
          <>
            <Search
              allowClear
              placeholder={translate("pages.service.placeholders.search")}
              onSearch={(value) =>
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value,
                  },
                ])
              }
            />
            {defaultButtons}
          </>
        )}
      >
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record, _) => {
            return {
              onClick: () => record.id && show("service", record.id),
            };
          }}
        >
          <Table.Column
            dataIndex="reference"
            title={translate("pages.service.fields.reference")}
            width="5%"
          />
          <Table.Column
            dataIndex="name"
            title={translate("pages.service.fields.name")}
            width="35%"
          />
          <Table.Column
            dataIndex="feeId"
            title={translate("pages.service.fields.fee_id")}
            width="5%"
          />
          <Table.Column
            dataIndex="active"
            title={translate("pages.service.fields.active")}
            width="5%"
            render={(value) => (Boolean(value) ? <CheckOutlined /> : <div />)}
            align="center"
          />
          <Table.Column
            dataIndex="ownValue"
            title={translate("pages.service.fields.own_value")}
            width="7%"
            render={(value) => Number(value).toFixed(2) + " €"}
            align="right"
          />
          <Table.Column
            dataIndex="feeThreshold"
            title={translate("pages.service.fields.fee_threshold")}
            width="7%"
            render={(value) => Number(value).toFixed(2) + " €"}
            align="right"
          />
          <Table.Column
            dataIndex="bayernValue"
            title={translate("pages.service.fields.bayern_value")}
            width="7%"
            render={(value) => Number(value).toFixed(2) + " €"}
            align="right"
          />
          <Table.Column
            dataIndex="bundValue"
            title={translate("pages.service.fields.bund_value")}
            width="7%"
            render={(value) => Number(value).toFixed(2) + " €"}
            align="right"
          />
          <Table.Column
            dataIndex="feeMin"
            title={translate("pages.service.fields.fee_min")}
            width="7%"
            render={(value) => Number(value).toFixed(2) + " €"}
            align="right"
          />
          <Table.Column
            dataIndex="feeMax"
            title={translate("pages.service.fields.fee_max")}
            width="7%"
            render={(value) => Number(value).toFixed(2) + " €"}
            align="right"
          />
        </Table>
      </List>
    </CanAccess>
  );
};
