import { useTranslate } from "@refinedev/core";
import { CSSProperties } from "react";
import { TextField } from "@refinedev/antd";
import { TextArea } from "antx";
import { HeaderText } from "../common";

type Props = {
  style?: CSSProperties;
  treatment?: string;
  editable?: boolean;
};

export const Treatment = ({ style, treatment, editable }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText
      title={translate("pages.treatment.fields.treatment")}
      style={style}
    >
      {editable ? (
        <TextArea
          name="treatment"
          autoSize={{ minRows: 6 }}
          style={{ margin: "-5px" }}
        />
      ) : (
        <TextField value={treatment} style={{ minHeight: "100px" }} />
      )}
    </HeaderText>
  );
};
