import { List, useModalForm, useTable } from "@refinedev/antd";
import { CanAccess, useInvalidate, useTranslate } from "@refinedev/core";
import { Button, Form, Modal, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Input } from "antx";
import { useState } from "react";
import {
  SETTINGS_LIST_QUERY,
  SETTING_EDIT_MUTATION,
  SETTING_SHOW_QUERY,
} from "../../../queries";
import { Unauthorized, EmptyData } from "../../../components/common";

export const GeneralsList = () => {

  const translate = useTranslate();
  const invalidate = useInvalidate();
  const { tableProps } = useTable({
    resource: "settings",
    syncWithLocation: true,
    meta: {
      gqlQuery: SETTINGS_LIST_QUERY,
    },
    pagination: {
      mode: "off",
    },
  });

  const { formProps, modalProps, show } = useModalForm({
    action: "edit",
    resource: "setting",
    meta: {
      gqlMutation: SETTING_EDIT_MUTATION,
      gqlQuery: SETTING_SHOW_QUERY,
    },
    onMutationSuccess: () =>
      invalidate({
        resource: "settings",
        invalidates: ["list"],
      }),
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <>
        <List breadcrumb={false}>
          <Table
            showHeader={false}
            {...tableProps}
            locale={{
              emptyText: (
                <EmptyData text={translate("empty_data")} height={60} />
              ),
            }}
            rowKey="id"
          >
            <Table.Column
              dataIndex="key"
              render={(value) => translate("pages.general.fields." + value)}
              width="15%"
            />
            <Table.Column dataIndex="value" />
            <Table.Column
              dataIndex="updatedAt"
              render={(value) =>
                new Date(value).toLocaleString(undefined, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })
              }
              width="20%"
            />
            <Table.Column
              render={(_, record: any) => (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    show(record?.id);
                  }}
                />
              )}
              width="5%"
            />
          </Table>
        </List>
        <Modal {...modalProps} title={translate("pages.general.titles.edit")}>
          <Form {...formProps} layout="vertical">
            <Input
              name="value"
              label={translate(
                "pages.general.fields." + formProps.initialValues?.key
              )}
              autoFocus
              getValueFromEvent={(e) =>
                String(formProps.initialValues?.key).endsWith("Reference")
                  ? String(
                      String(formProps.initialValues?.value).split("-")[0] +
                        "-" +
                        e.target.value +
                        "-" +
                        String(formProps.initialValues?.value).split("-")[2]
                    )
                  : e.target.value
              }
              getValueProps={(value) =>
                String(formProps.initialValues?.key).endsWith("Reference")
                  ? { value: String(value).split("-")[1] }
                  : { value }
              }
              prefix={
                String(formProps.initialValues?.key).endsWith("Reference")
                  ? String(formProps.initialValues?.value).split("-")[0] + "-"
                  : undefined
              }
              suffix={
                String(formProps.initialValues?.key).endsWith("Reference")
                  ? "-" + String(formProps.initialValues?.value).split("-")[2]
                  : undefined
              }
            />
          </Form>
        </Modal>
      </>
    </CanAccess>
  );
};
