import { Route } from "react-router-dom";
import { GeneralsList } from ".";
import { DatabaseOutlined } from "@ant-design/icons";
import { CanAccess } from "@refinedev/core";
import { Unauthorized } from "../../../components/common";

export const GeneralRoute = () => (
  <Route
    path="general"
    element={
      <CanAccess fallback={<Unauthorized />}>
        <GeneralsList />
      </CanAccess>
    }
  />
);

export const GeneralProps = {
  name: "general",
  meta: {
    parent: "setting",
    icon: <DatabaseOutlined />,
  },
  list: "/setting/general",
};
