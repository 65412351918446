import { Route } from "react-router-dom";
import { DatabaseOutlined } from "@ant-design/icons";
import { TemplateList } from "./list";

export const TemplateRoute = () => (
  <Route path="template">
    <Route index element={<TemplateList />} />
  </Route>
);

export const TemplateProps = {
  name: "receipttemplate",
  meta: {
    parent: "setting",
    icon: <DatabaseOutlined />,
  },
  list: "/setting/template",
};
