import { useTranslate } from "@refinedev/core";
import { Button, Form } from "antd";
import { useEffect, useRef, useState } from "react";
import { API_URL, gqlClient } from "../../provider";
import { UploadOutlined } from "@ant-design/icons";
import { updateFieldValue, UploadFile } from "../common";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

type Props = {
  url?: string;
  editable?: boolean;
};

export const Preview = ({ url, editable }: Props) => {
  const [src, setSrc] = useState("");

  const form = useFormInstance();

  useEffect(() => {
    if (url && url !== src) {
      setSrc(url);
    }
  }, [url]);

  const handleUpload = async (file: File) => {
    const data = new FormData();
    data.append("file", file, file.name);
    let response = await fetch(API_URL + "/files", {
      headers: gqlClient.requestConfig.headers as any,
      method: "POST",
      body: data,
    });

    if (response.status >= 200 && response.status <= 299) {
      const res = await response.json();
      return {
        name: file.name,
        url: res.url,
        key: res.key,
        size: file.size,
      };
    }
  };

  return (
    <>
      {editable && (
        <Form.Item
          name="file"
          style={{ paddingTop : 10 }}
          getValueFromEvent={(props) => {
            updateFieldValue(form, "name", props.name);
            setSrc(props.url);
            return {
              key: props.key,
              size: props.size,
            };
          }}
        >
          <UploadFile onUpload={handleUpload} />
        </Form.Item>
      )}
      <iframe
        src={src}
        style={{ border: 0, padding: 10, borderRadius: "17px" }}
        width="100%"
        height="100%"
        allowFullScreen
      />
    </>
  );
};
