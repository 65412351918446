import gql from "graphql-tag";

export const ICD_SEARCH_QUERY = gql`
  query Icds($paging: PagingInput!, $filter: FilterInput) {
    icds(paging: $paging, filter: $filter) {
      nodes {
        id
        createdAt
        updatedAt
        description
        code
        icdCategory {
          id
          createdAt
          updatedAt
          description
          start
          end
        }
      }
    }
  }
`;

export const ADDRESS_LIST_QUERY = gql`
  query Address($paging: PagingInput, $filter: FilterInput) {
    address(paging: $paging, filter: $filter) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        name
        street
        postcode
        city
        country
        type
        default
      }
      totalCount
    }
  }
`;