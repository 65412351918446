import {
  DeleteButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show,
} from "@refinedev/antd";
import {
  CanAccess,
  useNavigation,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { HeaderText, Unauthorized } from "../../components/common";
import { Col, Flex, Row, Typography } from "antd";
import { FILE_DELETE_MUTATION, FILE_SHOW_QUERY } from "../../queries";
import { Data, Preview } from "../../components/file";

export const FileShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      gqlQuery: FILE_SHOW_QUERY,
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Show
        title={translate("pages.file.titles.show")}
        breadcrumb={false}
        isLoading={isLoading}
        headerButtons={({
          deleteButtonProps,
          editButtonProps,
          listButtonProps,
          refreshButtonProps,
        }) => (
          <>
            {listButtonProps && <ListButton {...listButtonProps} />}
            {editButtonProps && <EditButton {...editButtonProps} />}
            {deleteButtonProps && (
              <DeleteButton
                {...deleteButtonProps}
                meta={{ gqlMutation: FILE_DELETE_MUTATION }}
              />
            )}
            <RefreshButton {...refreshButtonProps} />
          </>
        )}
      >
        <Row>
          <Col span={12}>
            <Preview url={record?.url} />
          </Col>
          <Col span={12}>
            <Data
              fileName={record?.name}
              patientName={record?.patient?.name}
              patientId={record?.patient?.id}
              createdAt={record?.createdAt}
              size={record?.size}
              description={record?.description}
            />
          </Col>
        </Row>
      </Show>
    </CanAccess>
  );
};
