import { useTranslate } from "@refinedev/core";
import { Col, Divider } from "antd";
import { TextField } from "@refinedev/antd";
import { Input } from "antx";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  email?: string;
  telPrivate?: string;
  telPublic?: string;
};

export const Contact = ({ editable, email, telPrivate, telPublic }: Props) => {
  const translate = useTranslate();

  return (
    <>
      <Divider style={{ paddingTop: "20px" }}>
        {translate("titles.contact")}
      </Divider>
      <Col md={8}>
        <HeaderText title={translate("pages.patient.fields.email")}>
          {editable ? (
            <Input
              name="email"
              style={{ margin: "-5px" }}
              rules={[
                {
                  required: true,
                  message: translate("validations.empty_email"),
                },
                {
                  type: "email",
                  message: translate("validations.format_email"),
                },
              ]}
            />
          ) : (
            <TextField value={email ?? "n.A."} />
          )}
        </HeaderText>
      </Col>
      <Col md={8}>
        <HeaderText title={translate("pages.patient.fields.tel_private")}>
          {editable ? (
            <Input name="telPrivate" style={{ margin: "-5px" }} />
          ) : (
            <TextField value={telPrivate ?? "n.A."} />
          )}
        </HeaderText>
      </Col>
      <Col md={8}>
        <HeaderText title={translate("pages.patient.fields.tel_public")}>
          {editable ? (
            <Input name="telPublic" style={{ margin: "-5px" }} />
          ) : (
            <TextField value={telPublic ?? "n.A."} />
          )}
        </HeaderText>
      </Col>
    </>
  );
};
