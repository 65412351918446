import { DateField, TextField, useTable } from "@refinedev/antd";
import { BaseKey, useNavigation, useTranslate } from "@refinedev/core";
import { Table, Tag } from "antd";
import { IListInvoice } from "../../types/patient";
import { INVOICES_LIST_QUERY } from "../../queries";
import { EmptyData } from "../common";

type Props = {
  patientId?: BaseKey;
};

export const Invoices = ({ patientId }: Props) => {
  const translate = useTranslate();
  const { show } = useNavigation();

  const colorStatus: { [key: string]: string } = {
    PAID: "green",
    OPEN: "red",
  };

  const { tableProps } = useTable<IListInvoice>({
    resource: "invoices",
    meta: {
      gqlQuery: INVOICES_LIST_QUERY,
    },
    syncWithLocation: false,
    filters: {
      permanent: [
        {
          field: "id",
          operator: "eq",
          value: patientId,
        },
      ],
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    pagination: {
      mode: "server",
    },
  });

  return (
    <Table
      {...tableProps}
      rowKey="id"
      onRow={(record) => {
        return {
          onClick: () => record.id && show("invoice", record.id),
        };
      }}
      rowHoverable
      locale={{
        emptyText : <EmptyData text={translate("empty_data")} />
      }}
    >
      <Table.Column
        dataIndex="reference"
        title={translate("pages.invoice.fields.reference")}
        width="20%"
        sorter
      />
      <Table.Column
        title={translate("pages.invoice.fields.createdAt")}
        dataIndex="createdAt"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="20%"
        sorter
      />
      <Table.Column
        title={translate("pages.invoice.fields.completedOn")}
        dataIndex="completedOn"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="20%"
        sorter
      />
      <Table.Column
        title={translate("pages.invoice.fields.amount")}
        dataIndex="amount"
        render={(value: any) => (
          <TextField value={Number(value).toFixed(2) + " EUR"} />
        )}
        width="20%"
        sorter
      />
      <Table.Column
        title={translate("selects.payment.title")}
        dataIndex="status"
        render={(value: any) => (
          <Tag color={colorStatus[value]}>
            {translate("selects.payment." + String(value).toLowerCase())}
          </Tag>
        )}
        width="20%"
        sorter
      />
    </Table>
  );
};
