import { Route } from "react-router-dom";
import { ProfileOutlined } from "@ant-design/icons";
import { CanAccess } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import {
  PatientCreate,
  PatientCreateWizard,
  PatientEdit,
  PatientShow,
  PatientsList,
} from ".";

export const PatientRoute = () => (
  <Route path="/patient">
    <Route
      index
      element={
        <CanAccess fallback={<Unauthorized />}>
          <PatientsList />
        </CanAccess>
      }
    />
    <Route
      path="create"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <PatientCreate />
        </CanAccess>
      }
    />
    <Route
      path="create_wizard"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <PatientCreateWizard />
        </CanAccess>
      }
    />
    <Route
      path="edit/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <PatientEdit />
        </CanAccess>
      }
    />
    <Route
      path="show/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <PatientShow />
        </CanAccess>
      }
    />
  </Route>
);

export const PatientProps = {
  name: "patient",
  list: "/patient",
  create: "/patient/create",
  edit: "/patient/edit/:id",
  show: "/patient/show/:id",
  meta: {
    canDelete: true,
    icon: <ProfileOutlined />,
  },
};
