import { DateField, List, TextField, useTable } from "@refinedev/antd";
import {
  CanAccess,
  CrudFilters,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Table } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Unauthorized } from "../../components/common";
import { INVOICES_LIST_QUERY } from "../../queries";

export const InvoicesList = () => {
  const [filters, setFilters] = useState<CrudFilters>([]);

  const translate = useTranslate();
  const { show } = useNavigation();
  const { tableProps } = useTable({
    resource: "invoices",
    syncWithLocation: true,
    meta: {
      gqlQuery: INVOICES_LIST_QUERY,
    },
    filters: {
      permanent: filters,
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <List
        headerButtons={({ defaultButtons }) => (
          <>
            <Search
              allowClear
              placeholder={translate("pages.invoice.placeholders.search")}
              onSearch={(value) =>
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value,
                  },
                ])
              }
            />
            {defaultButtons}
          </>
        )}
      >
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record, _) => {
            return {
              onClick: () => record.id && show("invoice", record.id),
            };
          }}
        >
          <Table.Column
            dataIndex="reference"
            title={translate("pages.invoice.fields.reference")}
            width="8%"
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            title={translate("pages.invoice.fields.createdAt")}
            render={(value) => <DateField value={value} locales="de-DE" />}
            width="10%"
            sorter
          />
          <Table.Column
            dataIndex="updatedAt"
            title={translate("pages.invoice.fields.updatedAt")}
            render={(value) => <DateField value={value} locales="de-DE" />}
            width="10%"
            sorter
          />
          <Table.Column
            dataIndex={["patient", "name"]}
            title={translate("pages.invoice.fields.name")}
            render={(value) => <TextField value={value} />}
            sorter
          />
          <Table.Column
            dataIndex="amount"
            title={translate("pages.invoice.fields.amount")}
            render={(value) => (
              <TextField value={Number(value).toFixed(2) + " €"} />
            )}
            align="right"
            sorter
          />
          <Table.Column
            dataIndex="status"
            title={translate("selects.payment.title")}
            width="10%"
            render={(value) => (
              <TextField
                value={translate(
                  "selects.payment." + String(value).toLowerCase()
                )}
                type={value === "OPEN" ? "danger" : "success"}
              />
            )}
            align="right"
            sorter
          />
          <Table.Column
            dataIndex="completedOn"
            title={translate("pages.invoice.fields.completedOn")}
            render={(value) =>
              value ? <DateField value={value} locales="de-DE" /> : undefined
            }
            width="10%"
            sorter
          />
        </Table>
      </List>
    </CanAccess>
  );
};
