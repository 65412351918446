import gql from "graphql-tag";

export const USER_SHOW_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      createdAt
      updatedAt
      email
      name
      role
    }
  }
`;

export const USERS_LIST_QUERY = gql`
  query Users($paging: PagingInput, $filter: FilterInput) {
    users(paging: $paging, filter: $filter) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        updatedAt
        email
        name
        role
      }
      totalCount
    }
  }
`;

export const USER_EDIT_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      createdAt
      updatedAt
      email
      name
      role
    }
  }
`;

export const USER_CREATE_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      createdAt
      updatedAt
      email
      name
      role
    }
  }
`;

export const USER_DELETE_MUTATION = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`;
