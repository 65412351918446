import { Empty } from "antd";

type Props = {
  text?: string;
  height?: number;
};

export const EmptyData = ({ text, height }: Props) => {
  return <Empty description={text} imageStyle={{ height }} />;
};
