import { UploadOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Button } from "antd";
import { useRef, useState } from "react";

type Props = {
  onChange?: (value: any) => void;
  onUpload?: (file: File) => Promise<any>;
};

export const UploadFile = ({ onChange, onUpload }: Props) => {
  const translate = useTranslate();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [upload, setUpload] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  return (
    <>
      <input
        type="file"
        ref={inputFile}
        onChange={async (event) => {
          if (event?.currentTarget?.files) {
            setUpload(true);
            if (onUpload) {
              let file = await onUpload(event?.currentTarget?.files[0]);
              onChange && onChange(file);
              setUploaded(true);
            }
            setUpload(false);
          }
        }}
        style={{ display: "none" }}
      />
      <Button
        type="dashed"
        onClick={() => inputFile?.current?.click()}
        block
        icon={<UploadOutlined />}
        disabled={upload}
      >
        {upload
          ? translate("buttons.upload")
          : uploaded
          ? translate("buttons.replace")
          : translate("buttons.add")}
      </Button>
    </>
  );
};
