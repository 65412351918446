import { Card, Divider, Flex } from "antd";
import { useTranslate } from "@refinedev/core";
import { Brutto, Discount, Discounted, Netto, Tax } from ".";

type Props = {
  amount?: number;
  tax?: string;
  discountType?: string;
  discountValue?: number;
  editable?: boolean;
};

export const Amount = (props: Props) => {
  const translate = useTranslate();

  return (
    <>
      <Divider>{translate("pages.invoice.fields.vat_discount_sum")}</Divider>
      <Card>
        <Flex justify="space-between" vertical gap={5}>
          <Netto
            amount={props.amount}
            tax={props.tax}
            editable={props.editable}
          />
          <Tax
            amount={props.amount}
            tax={props.tax}
            editable={props.editable}
          />
          <Brutto amount={props.amount} editable={props.editable} />
          <Discount
            amount={props.amount}
            discountType={props.discountType}
            discountValue={props.discountValue}
            editable={props.editable}
          />
          <Discounted
            amount={props.amount}
            discountType={props.discountType}
            discountValue={props.discountValue}
            editable={props.editable}
          />
        </Flex>
      </Card>
    </>
  );
};
