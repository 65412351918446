import { DeleteButton, EditButton, ListButton, RefreshButton, Show } from "@refinedev/antd";
import { CanAccess, useList, useShow, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { Info } from "../../components/receipt/info";
import { RECEIPT_DELETE_MUTATION, RECEIPT_SHOW_QUERY, SETTINGS_LIST_QUERY } from "../../queries";
import { Content, Name } from "../../components/receipt";
import { BlobProvider } from "@react-pdf/renderer";
import { Receipt } from "../../components/pdf";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

export const ReceiptShow = () => {
  const translate = useTranslate();
  const { query } = useShow({
    meta: {
      gqlQuery: RECEIPT_SHOW_QUERY,
    },
  });
  const { data, isLoading } = query;

  const record = data?.data;

  const { data: dataSettings } = useList({
    resource: "settings",
    meta: {
      gqlQuery: SETTINGS_LIST_QUERY,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Show
        title={translate("pages.receipt.titles.show")}
        breadcrumb={false}
        isLoading={isLoading}
        headerButtons={({
          deleteButtonProps,
          editButtonProps,
          listButtonProps,
          refreshButtonProps,
        }) => (
          <>
          {dataSettings?.data && (
            <BlobProvider
              document={
                <Receipt
                  translate={translate}
                  record={record}
                  settings={dataSettings.data}
                />
              }
            >
              {({ url }) => (
                <Button
                  icon={<PrinterOutlined />}
                  onClick={() => url && window.open(url, "_blank")?.focus()}
                >
                  {translate("buttons.print")}
                </Button>
              )}
            </BlobProvider>
          )}
            {listButtonProps && <ListButton {...listButtonProps} />}
            {editButtonProps && <EditButton {...editButtonProps} />}
            {deleteButtonProps && (
              <DeleteButton
                {...deleteButtonProps}
                meta={{ gqlMutation: RECEIPT_DELETE_MUTATION }}
              />
            )}
            <RefreshButton {...refreshButtonProps} />
          </>
        )}
      >
        <Info
          reference={record?.reference}
          createdAt={record?.createdAt}
          updatedAt={record?.updatedAt}
        />
        <Name id={record?.patient?.id} name={record?.patient?.name} />
        <Content content={record?.content} />
      </Show>
    </CanAccess>
  );
};
