import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Col, Divider } from "antd";
import { Input } from "antx";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  insuranceName?: string;
  insuranceNumber?: string;
};

export const Insurance = ({
  editable,
  insuranceName,
  insuranceNumber,
}: Props) => {
  const translate = useTranslate();

  return (
    <>
      <Divider style={{ paddingTop: "20px" }}>
        {translate("titles.insurance")}
      </Divider>
      <Col md={12}>
        <HeaderText title={translate("pages.patient.fields.insurance_name")}>
          {editable ? (
            <Input name="insuranceName" style={{ margin: "-5px" }} />
          ) : (
            <TextField value={insuranceName ?? "n.A."} />
          )}
        </HeaderText>
      </Col>
      <Col md={12}>
        <HeaderText title={translate("pages.patient.fields.insurance_number")}>
          {editable ? (
            <Input name="insuranceNumber" style={{ margin: "-5px" }} />
          ) : (
            <TextField value={insuranceNumber ?? "n.A."} />
          )}
        </HeaderText>
      </Col>
    </>
  );
}
