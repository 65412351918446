import { Create, useForm } from "@refinedev/antd";
import { CanAccess, useParsed, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { Form } from "antd";
import { Content, Name } from "../../components/receipt";
import { RECEIPT_CREATE_MUTATION } from "../../queries";

type Props = {
  patient?: string;
};

export const ReceiptCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps } = useForm({
    resource : "receipt",
    meta: {
      gqlMutation: RECEIPT_CREATE_MUTATION,
    },
  });

  const { params } = useParsed<Props>();

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Create
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        title={translate("pages.receipt.titles.create")}
      >
        <Form {...formProps} initialValues={{
          patient: params?.patient ? JSON.parse(params.patient) : undefined,
        }}>
          <Name editable />
          <Content editable />
        </Form>
      </Create>
    </CanAccess>
  );
};
