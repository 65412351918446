import { Typography } from "antd";

export const Unauthorized = () => {
  return (
    <div
      style={{
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography.Title>Unauthorized</Typography.Title>
    </div>
  );
};
