import { Col, Row } from "antd";
import { Block, Name, Type } from ".";

type Props = {
  editable?: boolean;
  record?: any;
};

export const Mid = ({ editable, record }: Props) => {
  return (
    <Row>
      <Col md={10}>
        <Name record={record} editable={editable} />
      </Col>
      <Col md={6}>
        <Type record={record} editable={editable} />
      </Col>
      <Col md={8}>
        <Block record={record} editable={editable} />
      </Col>
    </Row>
  );
};
