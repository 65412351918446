import { DateField, List, TextField, useTable } from "@refinedev/antd";
import {
  CanAccess,
  CrudFilters,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Table } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Unauthorized } from "../../components/common";
import { FILES_LIST_QUERY } from "../../queries";

export const FilesList = () => {
  const [filters, setFilters] = useState<CrudFilters>([]);

  const translate = useTranslate();
  const { show } = useNavigation();
  const { tableProps } = useTable({
    resource: "files",
    syncWithLocation: true,
    meta: {
      gqlQuery: FILES_LIST_QUERY,
    },
    filters: {
      permanent: filters,
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
  });

  const humanFileSize = (bytes: number, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };

  return (
    <CanAccess fallback={<Unauthorized />}>
      <List
        headerButtons={({ defaultButtons }) => (
          <>
            <Search
              allowClear
              placeholder={translate("pages.file.placeholders.search")}
              onSearch={(value) =>
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value,
                  },
                ])
              }
            />
            {defaultButtons}
          </>
        )}
      >
        <Table
          {...tableProps}
          rowKey="id"
          onRow={(record, _) => {
            return {
              onClick: () => record.id && show("file", record.id),
            };
          }}
        >
          <Table.Column
            dataIndex="id"
            title={translate("pages.file.fields.id")}
            width="8%"
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            title={translate("pages.file.fields.createdAt")}
            render={(value) => <DateField value={value} locales="de-DE" />}
            width="10%"
            sorter
          />
          <Table.Column
            dataIndex={["patient", "name"]}
            title={translate("pages.file.fields.patientName")}
            width="15%"
            sorter
          />
          <Table.Column
            dataIndex="name"
            title={translate("pages.file.fields.fileName")}
            sorter
          />
          <Table.Column
            dataIndex="size"
            title={translate("pages.file.fields.size")}
            render={(value: any) => <TextField value={humanFileSize(value, true)} />}
            width="10%"
            align="right"
            sorter
          />
        </Table>
      </List>
    </CanAccess>
  );
};
