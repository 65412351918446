import gql from "graphql-tag";

export const SETTINGS_LIST_QUERY = gql`
  query Settings {
    settings {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        updatedAt
        key
        value
      }
      totalCount
    }
  }
`;

export const SETTING_SHOW_QUERY = gql`
  query Setting($id: ID!) {
    setting(id: $id) {
      id
      createdAt
      updatedAt
      key
      value
    }
  }
`;

export const SETTING_EDIT_MUTATION = gql`
  mutation UpdateSetting($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      id
      createdAt
      updatedAt
      key
      value
    }
  }
`;