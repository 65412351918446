import { Divider, Flex, Space, Typography } from "antd";
import { useTranslate } from "@refinedev/core";
import { InputNumber, Select, Watch } from "antx";

const { Title } = Typography;

type Props = {
  amount?: number;
  discountValue?: number;
  discountType?: string;
  editable?: boolean;
};

export const Discount = (props: Props) => {
  const translate = useTranslate();

  const calculateDiscount = (
    amount?: number,
    discountType?: string,
    discountValue?: number
  ) => {
    let value = 0;
    if (discountType === "DIRECT") {
      value = discountValue ?? 0;
    } else {
      value = (amount ?? 0) - (amount ?? 0) * (1 - (discountValue ?? 0) / 100);
    }
    return value * -1;
  };

  return (
    <>
      <Divider dashed />
      <Title level={5} style={{ textAlign: "left" }}>
        {translate("selects.discount.title")}
      </Title>
      {props.editable ? (
        <Watch list={["discountType", "discountValue", "amount"]}>
          {([discountType, discountValue, amount]) => (
            <>
              <Flex gap={10} style={{ alignSelf: "flex-end" }}>
                <Select name="discountType" style={{ minWidth: "100px" }}>
                  <Select.Option value="DIRECT">
                    {translate("selects.discount.direct")}
                  </Select.Option>
                  <Select.Option value="PERCENT">
                    {translate("selects.discount.percent")}
                  </Select.Option>
                </Select>
                <InputNumber
                  name="discountValue"
                  style={{
                    alignSelf: "center",
                    maxWidth: "130px",
                    textAlign: "end",
                  }}
                  addonAfter={discountType === "DIRECT" ? "€" : "%"}
                />
              </Flex>
              <Space style={{ alignSelf: "end" }}>
                <span>
                  {calculateDiscount(
                    amount,
                    discountType,
                    discountValue
                  ).toFixed(2) + " €"}
                </span>
              </Space>
            </>
          )}
        </Watch>
      ) : (
        <Flex gap={10} style={{ alignSelf: "flex-end" }}>
          <span>
            {calculateDiscount(
              props.amount,
              props.discountType,
              props.discountValue
            ).toFixed(2) + " €"}
          </span>
        </Flex>
      )}
    </>
  );
};
