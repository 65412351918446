import {
  DeleteButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show,
} from "@refinedev/antd";
import {
  CanAccess,
  useNavigation,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Button, Divider, Dropdown, Row } from "antd";
import { PATIENT_DELETE_MUTATION, PATIENT_SHOW_QUERY } from "../../queries";
import { Unauthorized } from "../../components/common";
import {
  General,
  Contact,
  Insurance,
  Additional,
} from "../../components/patient";
import {
  DownOutlined,
  EuroCircleOutlined,
  FileOutlined,
  FileTextOutlined,
  HeartOutlined,
} from "@ant-design/icons";

export const PatientShow = () => {
  const translate = useTranslate();
  const { createUrl, push } = useNavigation();
  const { queryResult } = useShow({
    meta: {
      gqlQuery: PATIENT_SHOW_QUERY,
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Show
        title={translate("pages.patient.titles.show")}
        breadcrumb={false}
        isLoading={isLoading}
        headerButtons={({ deleteButtonProps, editButtonProps }) => (
          <>
            <Button
              type="primary"
              onClick={() =>
                push(
                  createUrl("treatment") +
                    "/" +
                    JSON.stringify({
                      id: record?.id,
                      name: record?.name,
                    })
                )
              }
              icon={<FileTextOutlined />}
            >
              {translate("buttons.create_treatment")}
            </Button>
            <Button
              type="primary"
              onClick={() =>
                push(
                  createUrl("invoice") +
                    "/" +
                    JSON.stringify({
                      id: record?.id,
                      name: record?.name,
                    })
                )
              }
              icon={<EuroCircleOutlined />}
            >
              {translate("buttons.create_invoice")}
            </Button>
            <Button
              type="primary"
              onClick={() =>
                push(
                  createUrl("receipt") +
                    "/" +
                    JSON.stringify({
                      id: record?.id,
                      name: record?.name,
                    })
                )
              }
              icon={<HeartOutlined />}
            >
              {translate("buttons.create_receipt")}
            </Button>
            <Button
              type="primary"
              onClick={() =>
                push(
                  createUrl("file") +
                    "/" +
                    JSON.stringify({
                      id: record?.id,
                      name: record?.name,
                    })
                )
              }
              icon={<FileOutlined />}
            >
              {translate("buttons.create_document")}
            </Button>
            <Divider type="vertical" />
            {editButtonProps && (
              <EditButton {...editButtonProps} type="default" />
            )}
            {deleteButtonProps && (
              <DeleteButton
                {...deleteButtonProps}
                type="primary"
                meta={{ gqlMutation: PATIENT_DELETE_MUTATION }}
              />
            )}
          </>
        )}
      >
        <Row gutter={20}>
          <General record={record} />
          <Contact
            email={record?.email}
            telPrivate={record?.telPrivate}
            telPublic={record?.telPublic}
          />
          <Insurance
            insuranceName={record?.insuranceName}
            insuranceNumber={record?.insuranceNumber}
          />
        </Row>
        <Additional patientId={record?.id} />
      </Show>
    </CanAccess>
  );
};
