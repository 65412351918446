import { useTranslate } from "@refinedev/core";
import { Divider, Typography } from "antd";
import { Watch } from "antx";

type Props = {
  amount?: number;
  discountValue?: number;
  discountType?: string;
  editable?: boolean;
};

export const Discounted = (props: Props) => {
  const translate = useTranslate();

  const calculateDiscounted = (
    amount?: number,
    discountType?: string,
    discountValue?: number
  ) => {
    if (!discountValue) {
      return amount ?? 0;
    }

    let discount = 0;
    if (discountType === "DIRECT") {
      discount = discountValue ?? 0;
    } else {
      discount =
        (amount ?? 0) - (amount ?? 0) * (1 - (discountValue ?? 0) / 100);
    }

    let val = (amount ?? 0) - discount;

    return val < 0 ? 0 : val;
  };

  return (
    <>
      <Divider />
      <Typography.Title level={5} style={{ textAlign: "left" }}>
        {translate("pages.invoice.fields.amount_discounted")}
      </Typography.Title>
      {props.editable ? (
        <Watch list={["amount", "discountType", "discountValue"]}>
          {([amount, discountType, discountValue]) => (
            <span style={{ alignSelf: "end" }}>
              {calculateDiscounted(
                Number(amount),
                discountType,
                Number(discountValue)
              ).toFixed(2) + " €"}
            </span>
          )}
        </Watch>
      ) : (
        <span style={{ alignSelf: "end" }}>
          {calculateDiscounted(
            props.amount,
            props.discountType,
            props.discountValue
          ).toFixed(2) + " €"}
        </span>
      )}
    </>
  );
};
