import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, InputRef, Tag } from "antd";
import { useState, useEffect, useRef, CSSProperties, ReactNode } from "react";
import { BaseRecord } from "@refinedev/core";
import { v4 as uuidv4 } from "uuid";
import { GroupOption, SearchAutoComplete } from ".";

type Props = {
  addNewLabel?: string;
  placeholder?: string;
  options?: GroupOption[];
  style?: CSSProperties;
  tags?: BaseRecord[];
  onAdd?: (add?: BaseRecord) => void;
  onRemove?: (remove: BaseRecord) => void;
  onSearch?: (term: string) => void;
  renderTag?: (item: BaseRecord) => ReactNode;
};

export const SearchTagsAutocomplete = ({
  addNewLabel,
  placeholder,
  options,
  style,
  tags,
  onAdd,
  onRemove,
  onSearch,
  renderTag,
}: Props) => {
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const handleClose = (removedTag: BaseRecord) => {
    if (onRemove) {
      onRemove(removedTag);
    }
  };

  const handleChange = (addedTag?: BaseRecord) => {
    if (onAdd) {
      onAdd(addedTag);
    }
    setInputVisible(false);
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  return (
    <div style={style}>
      <div style={{ marginBottom: 16 }}>
        <Flex gap={10} wrap="wrap">
          {tags?.map((x) => (
            <Tag key={uuidv4()} style={{ padding: "5px" }}>
              <Flex align="center" gap={5}>
                {renderTag && renderTag(x)}
                <Button
                  style={{ minWidth: "30px" }}
                  size="small"
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose(x);
                  }}
                  icon={<CloseOutlined />}
                />
              </Flex>
            </Tag>
          ))}
        </Flex>
      </div>
      {inputVisible ? (
        <SearchAutoComplete
          defaultOpen
          inputRef={inputRef}
          placeholder={placeholder}
          style={{ width: "100%", marginTop: "-25px" }}
          onSearch={onSearch}
          groupOptions={options}
          onChange={handleChange}
        />
      ) : (
        <Button
          onClick={() => setInputVisible(true)}
          type="dashed"
          block
          style={{ width: "100%", marginTop: (tags?.length ?? 0) > 0 ? "-5px" :"-25px" }}
        >
          <PlusOutlined /> {addNewLabel}
        </Button>
      )}
    </div>
  );
};
