import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { CSSProperties } from "react";
import { Watch } from "antx";
import { HeaderText } from "../common";

type Props = {
  reference?: string;
  style?: CSSProperties;
  editable?: boolean;
};

export const Reference = ({ reference, style, editable }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText
      title={translate("pages.treatment.fields.reference")}
      style={style}
    >
      {editable ? (
        <Watch name="reference">
          {(reference) => <TextField value={reference} />}
        </Watch>
      ) : (
        <TextField value={reference} />
      )}
    </HeaderText>
  );
};
