import { Route } from "react-router-dom";
import { EuroCircleOutlined } from "@ant-design/icons";
import { InvoiceCreate, InvoiceEdit, InvoiceShow, InvoicesList } from ".";
import { CanAccess } from "@refinedev/core";
import { Unauthorized } from "../../components/common";

export const InvoiceRoute = () => (
  <Route path="/invoice">
    <Route
      index
      element={
        <CanAccess fallback={<Unauthorized />}>
          <InvoicesList />
        </CanAccess>
      }
    />
    <Route
      path="create/:patient?"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <InvoiceCreate />
        </CanAccess>
      }
    />
    <Route
      path="edit/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <InvoiceEdit />
        </CanAccess>
      }
    />
    <Route
      path="show/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <InvoiceShow />
        </CanAccess>
      }
    />
  </Route>
);

export const InvoiceProps = {
  name: "invoice",
  list: "/invoice",
  create: "/invoice/create",
  edit: "/invoice/edit/:id",
  show: "/invoice/show/:id",
  meta: {
    canDelete: true,
    icon: <EuroCircleOutlined />,
  },
};
