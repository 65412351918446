import { Create, useForm } from "@refinedev/antd";
import { Form } from "antd";
import { CanAccess, useParsed, useTranslate } from "@refinedev/core";
import { Unauthorized } from "../../components/common";
import { TREATMENT_CREATE_MUTATION } from "../../queries";
import {
  General,
  Diagnose,
  Treatment,
  Results,
  Positions,
} from "../../components/treatment";

type Props = {
  patient?: string;
};

export const TreatmentCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps } = useForm({
    resource : "treatment",
    meta: {
      gqlMutation: TREATMENT_CREATE_MUTATION,
    },
  });

  const { params } = useParsed<Props>();

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Create
        saveButtonProps={saveButtonProps}
        breadcrumb={false}
        title={translate("pages.treatment.titles.create")}
      >
        <Form
          {...formProps}
          initialValues={{
            completedOn: undefined,
            positions: [],
            diagnoses: [],
            patient: params?.patient ? JSON.parse(params.patient) : undefined,
          }}
        >
          <General editable create/>
          <Diagnose editable/>
          <Treatment editable />
          <Results editable />
          <Positions editable />
        </Form>
      </Create>
    </CanAccess>
  );
};
