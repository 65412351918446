import { Card, Flex, Typography } from "antd";
import { CSSProperties, ReactNode } from "react";

type Props = {
  title?: string;
  children?: ReactNode[] | ReactNode;
  style?: CSSProperties;
};

export const HeaderText = ({ children, title, style }: Props) => {
  return (
    <Card title={title} style={{ margin: "10px" }}>
      <Flex vertical style={style}>
        {children}
      </Flex>
    </Card>
  );
};
