import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Input } from "antx";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  record?: any;
};

export const Name = ({ editable, record }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText title={translate("pages.service.fields.name")}>
      {editable ? (
        <Input
          name="name"
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: translate("validations.empty_name"),
            },
          ]}
        />
      ) : (
        <TextField value={record?.name ?? "n.A."} />
      )}
    </HeaderText>
  );
};
