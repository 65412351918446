import { BaseRecord } from "@refinedev/core";
import { AutoComplete } from "antd";
import { CSSProperties } from "react";

export type Option = { item: any; key: string | undefined; value: number };
export type GroupOption = { label: string; options: Option[] };

type Props = {
  inputRef?: any;
  placeholder?: string;
  defaultOpen?: boolean;
  options?: Option[];
  groupOptions?: GroupOption[];
  style?: CSSProperties;
  text?: any;
  onClear?: () => void;
  onSearch?: (term: string) => void;
  onChange?: (option?: BaseRecord) => void;
};

export const SearchAutoComplete = ({
  inputRef,
  defaultOpen,
  placeholder,
  options,
  groupOptions,
  style,
  text,
  onClear,
  onSearch,
  onChange,
}: Props) => {
  return options ? (
    <AutoComplete
      showSearch
      allowClear
      defaultOpen={defaultOpen}
      ref={inputRef}
      options={options}
      filterOption={false}
      placeholder={placeholder}
      onSearch={onSearch}
      onClear={() => {
        onChange && onChange(undefined);
        onClear && onClear();
      }}
      onSelect={(_, option) => onChange && onChange(option.item)}
      defaultValue={text}
      style={style}
    />
  ) : (
    <AutoComplete
      showSearch
      allowClear
      defaultOpen={defaultOpen}
      ref={inputRef}
      options={groupOptions}
      filterOption={false}
      placeholder={placeholder}
      onSearch={onSearch}
      onClear={onClear}
      onSelect={(_, option: any) => onChange && onChange(option.item)}
      defaultValue={text}
      style={style}
      popupMatchSelectWidth={false}
    />
  );
};
