import { Divider, Flex, Typography } from "antd";
import { useTranslate } from "@refinedev/core";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { Select, Watch } from "antx";

const { Title, Text } = Typography;

type Props = {
  amount?: number;
  tax?: string;
  setPreTaxValue?: (value: number) => void;
  editable?: boolean;
};

export const Tax = (props: Props) => {
  const translate = useTranslate();
  const form = useFormInstance();

  const calculateTax = (value: number, vatTax: string) => {
    let rate = 0;
    switch (vatTax) {
      case "VAT_7":
        rate = 0.07;
        break;
      case "VAT_19":
        rate = 0.19;
        break;
      case "VAT_0":
      default:
        break;
    }

    return ((value ?? 0) / (1 + rate)) * rate;
  };

  return (
    <>
      <Divider dashed />
      <Title level={5} style={{ textAlign: "left" }}>
        {translate("selects.vat.title")}
      </Title>
      {props.editable ? (
        <Flex style={{ alignSelf: "flex-end" }}>
          <Select name="tax" style={{ float: "right", width: "80px" }}>
            <Select.Option value="VAT_0">
              {translate("selects.vat.vat_0")}
            </Select.Option>
            <Select.Option value="VAT_7">
              {translate("selects.vat.vat_7")}
            </Select.Option>
            <Select.Option value="VAT_19">
              {translate("selects.vat.vat_19")}
            </Select.Option>
          </Select>
          <Watch list={["amount", "tax"]}>
            {([amount, tax]) => {
              return (
                <Typography.Text
                  style={{
                    marginLeft: 10,
                    float: "left",
                    marginTop: "4px",
                    width: "80px",
                    textAlign: "end",
                  }}
                >
                  {calculateTax(amount, tax).toFixed(2) + " €"}
                </Typography.Text>
              );
            }}
          </Watch>
        </Flex>
      ) : (
        <Flex gap={10} justify="flex-end">
          <Text style={{ minWidth: "80px" }}>
            {translate("selects.vat." + props.tax?.toLowerCase())}
          </Text>
          <Text
            style={{
              alignSelf: "center",
              minWidth: "80px",
              textAlign: "end",
            }}
          >
            {calculateTax(props.amount ?? 0, props.tax ?? "VAT_0").toFixed(2) +
              " €"}
          </Text>
        </Flex>
      )}
    </>
  );
};
