import React from "react";
import { Create, SaveButton, useStepsForm } from "@refinedev/antd";
import { PATIENT_CREATE_MUTATION } from "../../queries";
import { Button, Card, Flex, Form, List, Steps, Typography } from "antd";
import { Input, Select } from "antx";
import { CanAccess, useTranslate } from "@refinedev/core";
import dayjs from "dayjs";
import locale from "antd/locale/de_DE";
import { DatePicker } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { EmptyData, Unauthorized } from "../../components/common";

export const PatientCreateWizard = () => {
  const translate = useTranslate();

  const { current, gotoStep, stepsProps, formProps, saveButtonProps, submit } =
    useStepsForm({
      resource: "patient",
      meta: {
        gqlMutation: PATIENT_CREATE_MUTATION,
      },
    });

  const formList = [
    <>
      <Input
        label={translate("pages.patient.fields.salutation")}
        name="salutation"
      />
      <Input
        label={translate("pages.patient.fields.name")}
        name="name"
        rules={[
          {
            required: true,
            message: translate("validations.empty_name"),
          },
        ]}
      />
      <Form.Item
        label={translate("pages.patient.fields.birthdate")}
        name="birthdate"
        getValueProps={(value: any) => ({
          value: value ? dayjs(value) : null,
        })}
        getValueFromEvent={(value: any) =>
          value ? dayjs(value).format("YYYY-MM-DD") : null
        }
        rules={[
          {
            required: true,
            message: translate("validations.empty_birthdate"),
          },
        ]}
      >
        <DatePicker
          style={{ width: "100%" }}
          locale={locale.DatePicker}
          format={"DD.MM.YYYY"}
        />
      </Form.Item>
    </>,
    <>
      <Form.List name="address">
        {(fields, { add, remove }) => (
          <>
            {fields.length > 0 ? (
              <List
                locale={{ emptyText: <div /> }}
                dataSource={fields}
                grid={{ column: 2, gutter: [8, 8] }}
                renderItem={({ key, name, ...restField }) => (
                  <Card
                    size="small"
                    type="inner"
                    key={key}
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                    extra={
                      fields.length > 1 && (
                        <CloseOutlined
                          style={{ paddingLeft: "10px" }}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      )
                    }
                    title={
                      <Select
                        name={[name, "type"]}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Select.Option value="HOME">
                          {translate("selects.address.home")}
                        </Select.Option>
                        <Select.Option value="INVOICE">
                          {translate("selects.address.invoice")}
                        </Select.Option>
                        <Select.Option value="COMPANY">
                          {translate("selects.address.company")}
                        </Select.Option>
                      </Select>
                    }
                  >
                    <Flex vertical gap={5}>
                      <Input
                        placeholder={translate("pages.patient.fields.name")}
                        name={[name, "name"]}
                        style={{ margin: "0px" }}
                        rules={[
                          {
                            required: true,
                            message: translate("validations.empty_name"),
                          },
                        ]}
                      />
                      <Input
                        placeholder={translate(
                          "pages.patient.fields.address.street"
                        )}
                        name={[name, "street"]}
                        style={{ margin: "0px" }}
                        rules={[
                          {
                            required: true,
                            message: translate("validations.empty_street"),
                          },
                        ]}
                      />
                      <Flex gap={5}>
                        <Input
                          placeholder={translate(
                            "pages.patient.fields.address.postcode"
                          )}
                          name={[name, "postcode"]}
                          style={{ margin: "0px" }}
                          rules={[
                            {
                              required: true,
                              message: translate("validations.empty_postcode"),
                            },
                          ]}
                        />
                        <Input
                          placeholder={translate(
                            "pages.patient.fields.address.city"
                          )}
                          name={[name, "city"]}
                          style={{ margin: "0px" }}
                          rules={[
                            {
                              required: true,
                              message: translate("validations.empty_city"),
                            },
                          ]}
                        />
                      </Flex>
                      <Input
                        placeholder={translate(
                          "pages.patient.fields.address.country"
                        )}
                        name={[name, "country"]}
                        style={{ margin: "0px" }}
                        rules={[
                          {
                            required: true,
                            message: translate("validations.empty_country"),
                          },
                        ]}
                      />
                    </Flex>
                  </Card>
                )}
              />
            ) : (
              <EmptyData text={translate("empty_data")} height={80} />
            )}
            <Form.Item style={{ margin: "0px", paddingTop: "10px" }}>
              <Button
                type="dashed"
                onClick={() => {
                  add({
                    default: false,
                    type: "HOME",
                  });
                }}
                block
              >
                <PlusOutlined /> {translate("buttons.add")}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>,
    <>
      <Input
        label={translate("pages.patient.fields.email")}
        name="email"
        rules={[
          {
            required: true,
            message: translate("validations.empty_email"),
          },
          {
            type: "email",
            message: translate("validations.format_email"),
          },
        ]}
      />
      <Input
        label={translate("pages.patient.fields.tel_private")}
        name="telPrivate"
      />
      <Input
        label={translate("pages.patient.fields.tel_public")}
        name="telPublic"
      />
    </>,
    <>
      <Input
        label={translate("pages.patient.fields.insurance_name")}
        name="insuranceName"
      />
      <Input
        label={translate("pages.patient.fields.insurance_number")}
        name="insuranceNumber"
      />
    </>,
  ];

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Create
        breadcrumb={false}
        footerButtons={
          <>
            {current > 0 && (
              <Button
                onClick={() => {
                  gotoStep(current - 1);
                }}
              >
                {translate("buttons.previous")}
              </Button>
            )}
            {current < formList.length - 1 && (
              <Button
                onClick={() => {
                  gotoStep(current + 1);
                }}
              >
                {translate("buttons.next")}
              </Button>
            )}
            {current === formList.length - 1 && (
              <SaveButton
                {...saveButtonProps}
                style={{ marginRight: 10 }}
                onClick={() => submit()}
              />
            )}
          </>
        }
        title={translate("pages.patient.titles.create")}
      >
        <Steps {...stepsProps} labelPlacement="horizontal">
          <Steps.Step title={translate("titles.general")} />
          <Steps.Step title={translate("titles.address")} />
          <Steps.Step title={translate("titles.contact")} />
          <Steps.Step title={translate("titles.insurance")} />
        </Steps>
        <Form
          {...formProps}
          initialValues={{
            address: [{ type: "HOME", default: true }],
          }}
          style={{
            paddingTop: 20,
            maxWidth: 500,
            margin: "auto",
          }}
          layout="vertical"
        >
          {formList[current]}
        </Form>
      </Create>
    </CanAccess>
  );
};
