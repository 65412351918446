import { Document, Font, Page, Text, View, Image } from "@react-pdf/renderer";
import { Table, TR, TD } from "@ag-media/react-pdf-table";
import { BaseRecord } from "@refinedev/core";
import dayjs from "dayjs";

type Props = {
  record?: BaseRecord;
  settings?: BaseRecord[];
  translate?: any;
};

export const Receipt = ({ record, settings, translate }: Props) => {
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "/fonts/Roboto-Regular.ttf",
      },
      {
        src: "/fonts/Roboto-Bold.ttf",
        fontWeight: 600,
      },
    ],
  });

  const Logo = () => (
    <View
      style={{
        width: 120,
        alignSelf: "center",
      }}
    >
      <Image src="/images/logo.png" />
    </View>
  );

  const SenderLine = () => (
    <Text
      style={{ marginTop: 20, fontSize: 7, color: "grey", marginBottom: 5 }}
    >
      {settings?.find((x) => x.key === "companyName")?.value} -{" "}
      {settings?.find((x) => x.key === "companyStreet")?.value} -{" "}
      {settings?.find((x) => x.key === "companyPostcode")?.value}{" "}
      {settings?.find((x) => x.key === "companyCity")?.value}
    </Text>
  );

  const ReceiverAddress = () => (
    <View style={{ lineHeight: 1.3, fontSize: 10 }}>
      <Text>{record?.name}</Text>
    </View>
  );

  const SenderAddress = () => (
    <View style={{ textAlign: "right", lineHeight: 1.3, fontSize: 10 }}>
      <Text>{settings?.find((x) => x.key === "companyName")?.value}</Text>
      <Text>{translate("pdf.job")}</Text>
      <Text>{settings?.find((x) => x.key === "companyStreet")?.value}</Text>
      <Text>
        {settings?.find((x) => x.key === "companyPostcode")?.value}{" "}
        {settings?.find((x) => x.key === "companyCity")?.value}
      </Text>
    </View>
  );

  const ReceiptData = () => (
    <View
      style={{
        textAlign: "right",
        lineHeight: 1.3,
        fontSize: 10,
        paddingTop: 20,
      }}
    >
      <Text>
        {translate("pdf.date")}: {dayjs(record?.createdAt).format("L")}
      </Text>
      <Text>
        {translate("pdf.reference")}: {record?.reference}
      </Text>
    </View>
  );

  const Content = () => {
    if (record?.content && record?.content !== null) {
      return (
        <View style={{ paddingTop: 10 }}>
          <Text style={{ fontSize: 10, paddingTop: 5 }}>
            {record?.content}
          </Text>
        </View>
      );
    } else {
      return <div />;
    }
  };

  const Title = () => (
    <Text style={{ paddingTop: 20, fontWeight: 600 }}>
      {translate("pages.receipt.titles.singular")}
    </Text>
  );

  const Footer = () => (
    <View
      style={{
        fontSize: 8,
        color: "grey",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 30,
        left: 50,
        right: 50,
      }}
      fixed
    >
      <Table style={{ border: 0 }} trStyle={{ height: 10 }}>
        <TR>
          <TD weighting={0.4}>
            {settings?.find((x) => x.key === "companyName")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            {settings?.find((x) => x.key === "companyStreet")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            {settings?.find((x) => x.key === "companyPostcode")?.value}{" "}
            {settings?.find((x) => x.key === "companyCity")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            Tel: {settings?.find((x) => x.key === "companyTel")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            Fax: {settings?.find((x) => x.key === "companyFax")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            {settings?.find((x) => x.key === "companyEmail")?.value}
          </TD>
        </TR>
      </Table>
    </View>
  );

  const PageNumber = () => (
    <Text
      style={{ fontSize: 10, position: "absolute", bottom: 10, right: 10 }}
      render={({ pageNumber, totalPages }) =>
        translate("pdf.pageOf", { current: pageNumber, last: totalPages })
      }
      fixed
    />
  );

  return (
    <Document>
      <Page
        size="A4"
        style={{
          padding: 50,
          paddingTop: 20,
          paddingBottom: 120,
          fontFamily: "Roboto",
        }}
      >
        <Logo />
        <SenderLine />
        <ReceiverAddress />
        <SenderAddress />
        <ReceiptData />
        <Title />
        <Content />
        <Footer />
        <PageNumber />
      </Page>
    </Document>
  );
};
