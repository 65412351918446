import { BaseKey, CrudFilters, useList, useNavigation, useTranslate } from "@refinedev/core";
import { CSSProperties, useEffect, useState } from "react";
import { TextField } from "@refinedev/antd";
import { Form } from "antd";
import { Watch } from "antx";
import { PATIENTS_SEARCH_QUERY } from "../../queries";
import { HeaderText, SearchAutoComplete } from "../common";

type Props = {
  id? : BaseKey;
  name?: string;
  style?: CSSProperties;
  editable?: boolean;
};

export const Name = ({ id, name, style, editable }: Props) => {
  const translate = useTranslate();
  const { showUrl } = useNavigation();

  const [filters, setFilters] = useState<CrudFilters>();
  const [options, setOptions] = useState<{ item: any; key: any; value: any }[]>(
    []
  );

  const { data } = useList({
    resource: "patients",
    meta: {
      gqlQuery: PATIENTS_SEARCH_QUERY,
    },
    filters: filters,
    pagination: {
      pageSize: 20,
      mode: "server",
    },
  });

  useEffect(() => {
    if (data?.data) {
      setOptions(data.data.map((x) => ({ item: x, key: x.id, value: x.name })));
    }
  }, [data]);

  return (
    <HeaderText title={translate("pages.file.fields.patientName")} style={style}>
      {editable ? (
        <Watch name="patient">
          {(patient) => (
            <Form.Item name="patient" style={{ margin: "-5px" }} rules={[{
              required : true,
              message : translate("validations.empty_name")
            }]}>
              <SearchAutoComplete
                placeholder={translate("pages.file.placeholders.patientName")}
                onClear={() => setFilters(undefined)}
                onSearch={(value) =>
                  setFilters([
                    {
                      field: "name",
                      operator: "contains",
                      value,
                    },
                  ])
                }
                text={patient?.name}
                options={options}
                style={style}
              />
            </Form.Item>
          )}
        </Watch>
      ) : (
        <a type="link" href={showUrl("patient", id ?? 0)}>
          {name}
        </a>
      )}
    </HeaderText>
  );
};
