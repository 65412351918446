import { CrudFilters, useList, useTranslate } from "@refinedev/core";
import { CSSProperties, useEffect, useState } from "react";
import { TextField } from "@refinedev/antd";
import { Form } from "antd";
import { Watch } from "antx";
import { PATIENTS_SEARCH_QUERY } from "../../queries";
import { HeaderText, SearchAutoComplete } from "../common";

type Props = {
  name?: string;
  style?: CSSProperties;
  editable?: boolean;
};

export const Name = ({ name, style, editable }: Props) => {
  const translate = useTranslate();

  const [filters, setFilters] = useState<CrudFilters>();
  const [options, setOptions] = useState<{ item: any; key: any; value: any }[]>(
    []
  );

  const { data } = useList({
    resource: "patients",
    meta: {
      gqlQuery: PATIENTS_SEARCH_QUERY,
    },
    filters: filters,
    pagination: {
      pageSize: 20,
      mode: "server",
    },
  });

  useEffect(() => {
    if (data?.data) {
      setOptions(data.data.map((x) => ({ item: x, key: x.id, value: x.name })));
    }
  }, [data]);

  return (
    <HeaderText title={translate("pages.treatment.fields.name")} style={style}>
      {editable ? (
        <Watch name="patient">
          {(patient) => (
            <Form.Item
              name="patient"
              style={{ margin: "-5px" }}
              rules={[
                {
                  required: true,
                  message: translate("validations.empty_patient"),
                },
              ]}
            >
              <SearchAutoComplete
                placeholder={translate("pages.treatment.placeholders.name")}
                onClear={() => setFilters(undefined)}
                onSearch={(value) =>
                  setFilters([
                    {
                      field: "name",
                      operator: "contains",
                      value,
                    },
                  ])
                }
                text={patient?.name}
                options={options}
                style={style}
              />
            </Form.Item>
          )}
        </Watch>
      ) : (
        <TextField value={name} />
      )}
    </HeaderText>
  );
};
