import { Col, Row, Typography } from "antd";
import { HeaderText } from "../common";
import { useTranslate } from "@refinedev/core";
import { Watch } from "antx";

type Props = {
  reference?: string;
  createdAt?: string;
  updatedAt?: string;
  editable?: boolean;
};

export const Info = ({ reference, createdAt, updatedAt, editable }: Props) => {
  const translate = useTranslate();
  return (
    <Row>
      <Col span={8}>
        <HeaderText title={translate("pages.receipt.fields.reference")}>
          {editable ? (
            <Watch name="reference">
              {(reference) => <Typography.Text>{reference}</Typography.Text>}
            </Watch>
          ) : (
            <Typography.Text>{reference}</Typography.Text>
          )}
        </HeaderText>
      </Col>
      <Col span={8}>
        <HeaderText title={translate("pages.receipt.fields.createdAt")}>
          {editable ? (
            <Watch name="createdAt">
              {(createdAt) => (
                <Typography.Text>
                  {createdAt
                    ? new Date(createdAt).toLocaleDateString()
                    : "n.A."}
                </Typography.Text>
              )}
            </Watch>
          ) : (
            <Typography.Text>
              {createdAt ? new Date(createdAt).toLocaleDateString() : "n.A."}
            </Typography.Text>
          )}
        </HeaderText>
      </Col>
      <Col span={8}>
        <HeaderText title={translate("pages.receipt.fields.updatedAt")}>
          {editable ? (
            <Watch name="updatedAt">
              {(updatedAt) => (
                <Typography.Text>
                  {updatedAt
                    ? new Date(updatedAt).toLocaleDateString()
                    : "n.A."}
                </Typography.Text>
              )}
            </Watch>
          ) : (
            <Typography.Text>
              {updatedAt ? new Date(updatedAt).toLocaleDateString() : "n.A."}
            </Typography.Text>
          )}
        </HeaderText>
      </Col>
    </Row>
  );
};
