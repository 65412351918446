import { DateField, TextField, useTable } from "@refinedev/antd";
import { BaseKey, useNavigation, useTranslate } from "@refinedev/core";
import { Table } from "antd";
import { RECEIPTS_LIST_QUERY } from "../../queries";
import { IListReceipt } from "../../types/patient";
import { EmptyData } from "../common";

type Props = {
  patientId?: BaseKey;
};

export const Receipts = ({ patientId }: Props) => {
  const translate = useTranslate();
  const { show } = useNavigation();

  const truncateString = (str: string, num: number) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const { tableProps } = useTable<IListReceipt>({
    resource: "receipts",
    meta: {
      gqlQuery: RECEIPTS_LIST_QUERY,
    },
    syncWithLocation: false,
    filters: {
      permanent: [
        {
          field: "id",
          operator: "eq",
          value: patientId,
        },
      ],
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    pagination: {
      mode: "server",
    },
  });

  return (
    <Table
      {...tableProps}
      rowKey="id"
      onRow={(record) => {
        return {
          onClick: () => record.id && show("receipt", record.id),
        };
      }}
      rowHoverable
      locale={{
        emptyText : <EmptyData text={translate("empty_data")} />
      }}
    >
      <Table.Column
        title={translate("pages.receipt.fields.reference")}
        dataIndex="reference"
        width="10%"
        sorter
      />
      <Table.Column
        title={translate("pages.receipt.fields.createdAt")}
        dataIndex="createdAt"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="10%"
        sorter
      />
      <Table.Column
        title={translate("pages.receipt.fields.content")}
        dataIndex="content"
        render={(value: any) => (
          <TextField value={truncateString(String(value ?? "n.A."), 150)} />
        )}
        width="72%"
        sorter
      />
    </Table>
  );
};
