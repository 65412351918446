import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { TextField } from "@refinedev/antd";
import { Row, Col } from "antd";
import { useTranslate } from "@refinedev/core";
import { Checkbox, Input, Watch } from "antx";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  record?: any;
};

export const Header = ({ editable, record }: Props) => {
  const translate = useTranslate();

  return (
    <Row>
      <Col>
        <HeaderText title={translate("pages.service.fields.reference")}>
          {editable ? (
            <Input
              name="reference"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: translate("validations.empty_reference"),
                },
              ]}
            />
          ) : (
            <TextField value={record?.reference ?? "n.A."} />
          )}
        </HeaderText>
      </Col>
      <Col>
        <HeaderText title={translate("pages.service.fields.created_at")}>
          {editable ? (
            <Watch name="createdAt">
              {(createdAt) => (
                <TextField
                  style={{ padding: 5 }}
                  value={new Date(createdAt ?? "n.A.").toLocaleDateString(
                    undefined,
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}
                />
              )}
            </Watch>
          ) : (
            <TextField
              value={new Date(record?.createdAt ?? "n.A.").toLocaleDateString()}
            />
          )}
        </HeaderText>
      </Col>
      <Col>
        <HeaderText title={translate("pages.service.fields.updated_at")}>
          {editable ? (
            <Watch name="updatedAt">
              {(updatedAt) => (
                <TextField
                  style={{ padding: 5 }}
                  value={new Date(updatedAt ?? "n.A.").toLocaleDateString(
                    undefined,
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}
                />
              )}
            </Watch>
          ) : (
            <TextField
              value={new Date(record?.updatedAt ?? "n.A.").toLocaleDateString(
                undefined,
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }
              )}
            />
          )}
        </HeaderText>
      </Col>
      <Col>
        <HeaderText title={translate("pages.service.fields.fee_id")}>
          {editable ? (
            <Input name="feeId" style={{ margin: 0 }} />
          ) : (
            <TextField value={record?.feeId ?? "n.A."} />
          )}
        </HeaderText>
      </Col>
      <Col>
        <HeaderText title={translate("pages.service.fields.active")}>
          {editable ? (
            <Checkbox
              valuePropName="checked"
              name="active"
              style={{ margin: 0, alignSelf: "center" }}
            />
          ) : Boolean(record?.active ?? false) ? (
            <CheckOutlined style={{ alignSelf: "center" }} />
          ) : (
            <CloseOutlined style={{ alignSelf: "center" }} />
          )}
        </HeaderText>
      </Col>
    </Row>
  );
};
