import { Edit, useForm } from "@refinedev/antd";
import { CanAccess, useTranslate } from "@refinedev/core";
import { Form } from "antd";
import { Unauthorized } from "../../../components/common";
import { Mid, Description, Header, Footer } from "../../../components/service";
import { SERVICE_EDIT_MUTATION, SERVICE_DELETE_MUTATION } from "../../../queries";

export const ServiceEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, formLoading } = useForm({
    meta: {
      gqlMutation: SERVICE_EDIT_MUTATION,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Edit
        saveButtonProps={saveButtonProps}
        title={translate("pages.service.titles.edit")}
        deleteButtonProps={{ meta: { gqlMutation: SERVICE_DELETE_MUTATION } }}
        breadcrumb={false}
        isLoading={formLoading}
      >
        <Form {...formProps}>
          <Header editable />
          <Mid editable />
          <Description editable />
          <Footer editable />
        </Form>
      </Edit>
    </CanAccess>
  );
};
