import dataProvider, {
  GraphQLClient,
  liveProvider,
} from "@refinedev/nestjs-query";
import { createClient } from "graphql-ws";

export const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://beta.api.bloomers-berlin.de";;
export const WS_URL = "wss://api.nestjs-query.refine.dev/graphql";

export const gqlClient = new GraphQLClient(API_URL + "/graphql");
export const wsClient = createClient({ url: WS_URL });
export const data = dataProvider(gqlClient);
export const live = liveProvider(wsClient);
