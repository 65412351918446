import { Create, useForm } from "@refinedev/antd";
import { CanAccess, useTranslate } from "@refinedev/core";
import { Form } from "antd";
import { Unauthorized } from "../../../components/common";
import { Description, Footer, Header, Mid } from "../../../components/service";
import { SERVICE_CREATE_MUTATION } from "../../../queries";

export const ServiceCreate = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, formLoading } = useForm({
    meta: {
      gqlMutation: SERVICE_CREATE_MUTATION,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Create
        saveButtonProps={saveButtonProps}
        title={translate("pages.service.titles.create")}
        breadcrumb={false}
        isLoading={formLoading}
      >
        <Form
          {...formProps}
          initialValues={{
            createdAt: new Date(),
            updatedAt: new Date(),
            ownValue: 0,
            feeThreshold: 0,
            bayernValue: 0,
            bundValue: 0,
            feeMin: 0,
            feeMax: 0,
            active: true,
          }}
        >
          <Header editable />
          <Mid editable />
          <Description editable />
          <Footer editable />
        </Form>
      </Create>
    </CanAccess>
  );
};
