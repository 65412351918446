import {
  CanAccess,
  useDelete,
  useInvalidate,
  useTranslate,
} from "@refinedev/core";
import { EmptyData, Unauthorized } from "../../../components/common";
import { CreateButton, List, useModalForm, useTable } from "@refinedev/antd";
import { Button, Form, Modal, Popconfirm, Table, Typography } from "antd";
import {
  RECEIPT_TEMPLATE_CREATE_MUTATION,
  RECEIPT_TEMPLATE_DELETE_MUTATION,
  RECEIPT_TEMPLATE_SHOW_QUERY,
  RECEIPT_TEMPLATE_UPDATE_MUTATION,
  RECEIPT_TEMPLATES_QUERY,
} from "../../../queries";
import { Input, TextArea } from "antx";
import { DeleteOutlined } from "@ant-design/icons";

export const TemplateList = () => {
  const translate = useTranslate();
  const invalidate = useInvalidate();

  const { tableProps } = useTable({
    resource: "receiptTemplates",
    syncWithLocation: true,
    meta: {
      gqlQuery: RECEIPT_TEMPLATES_QUERY,
    },
    pagination: {
      mode: "off",
    },
  });

  const { mutateAsync: deleteAsync } = useDelete();

  const {
    formProps: formPropsEdit,
    modalProps: modalPropsEdit,
    show: showEdit,
  } = useModalForm({
    action: "edit",
    resource: "receiptTemplate",
    meta: {
      gqlQuery: RECEIPT_TEMPLATE_SHOW_QUERY,
      gqlMutation: RECEIPT_TEMPLATE_UPDATE_MUTATION,
    },
    onMutationSuccess: () =>
      invalidate({
        resource: "receiptTemplates",
        invalidates: ["list"],
      }),
  });

  const {
    formProps: formPropsCreate,
    modalProps: modalPropsCreate,
    show: showCreate,
  } = useModalForm({
    action: "create",
    resource: "receiptTemplate",
    meta: {
      gqlMutation: RECEIPT_TEMPLATE_CREATE_MUTATION,
    },
    onMutationSuccess: () =>
      invalidate({
        resource: "receiptTemplates",
        invalidates: ["list"],
      }),
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <>
        <List
          breadcrumb={false}
          headerButtons={<CreateButton onClick={() => showCreate()} />}
          title={translate("pages.receipttemplate.titles.plural")}
        >
          <Table
            showHeader={false}
            {...tableProps}
            locale={{
              emptyText: (
                <EmptyData text={translate("empty_data")} height={60} />
              ),
            }}
            rowKey="id"
            onRow={(record) => {
              return {
                onClick: () => record.id && showEdit(record.id),
              };
            }}
          >
            <Table.Column dataIndex="name" width="20%" />
            <Table.Column
              dataIndex="content"
              render={(value) => (
                <Typography.Paragraph ellipsis={{ rows: 3 }}>
                  {value}
                </Typography.Paragraph>
              )}
            />
            <Table.Column
              render={(_, record: any) => (
                <Popconfirm
                  title={translate("buttons.confirm")}
                  okText={translate("buttons.delete")}
                  cancelText={translate("buttons.cancel")}
                  onCancel={(e) => {
                    e?.stopPropagation();
                  }}
                  onConfirm={async (e) => {
                    e?.stopPropagation();
                    await deleteAsync({
                      resource: "receipttemplate",
                      meta: {
                        gqlMutation: RECEIPT_TEMPLATE_DELETE_MUTATION,
                      },
                      id: record?.id,
                    });
                    invalidate({
                      resource: "receiptTemplates",
                      invalidates: ["list"],
                    });
                  }}
                >
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Popconfirm>
              )}
            />
          </Table>
        </List>
        <Modal
          {...modalPropsEdit}
          title={translate("pages.receipttemplate.titles.edit")}
        >
          <Form {...formPropsEdit} layout="vertical">
            <Input
              name="name"
              label={translate("pages.receipttemplate.fields.name")}
              rules={[
                {
                  required: true,
                  message: translate("validations.empty_name"),
                },
              ]}
            />
            <TextArea
              name="content"
              label={translate("pages.receipttemplate.fields.content")}
              autoSize={{ minRows: 10 }}
              rules={[
                {
                  required: true,
                  message: translate("validations.empty_content"),
                },
              ]}
            />
          </Form>
        </Modal>
        <Modal
          {...modalPropsCreate}
          title={translate("pages.receipttemplate.titles.create")}
        >
          <Form {...formPropsCreate} layout="vertical">
            <Input
              name="name"
              label={translate("pages.receipttemplate.fields.name")}
              rules={[
                {
                  required: true,
                  message: translate("validations.empty_name"),
                },
              ]}
            />
            <TextArea
              name="content"
              label={translate("pages.receipttemplate.fields.content")}
              autoSize={{ minRows: 10 }}
              rules={[
                {
                  required: true,
                  message: translate("validations.empty_content"),
                },
              ]}
            />
          </Form>
        </Modal>
      </>
    </CanAccess>
  );
};
