import {
  DeleteButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show,
} from "@refinedev/antd";
import { CanAccess, useShow, useTranslate } from "@refinedev/core";
import { SERVICE_DELETE_MUTATION, SERVICE_SHOW_QUERY } from "../../../queries";
import { Unauthorized } from "../../../components/common";
import { Description, Footer, Header, Mid } from "../../../components/service";

export const ServiceShow = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    meta: {
      gqlQuery: SERVICE_SHOW_QUERY,
    },
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Show
        title={translate("pages.service.titles.show")}
        breadcrumb={false}
        isLoading={isLoading}
        headerButtons={({
          deleteButtonProps,
          editButtonProps,
          listButtonProps,
          refreshButtonProps,
        }) => (
          <>
            {listButtonProps && <ListButton {...listButtonProps} />}
            {editButtonProps && <EditButton {...editButtonProps} />}
            {deleteButtonProps && (
              <DeleteButton
                {...deleteButtonProps}
                meta={{ gqlMutation: SERVICE_DELETE_MUTATION }}
              />
            )}
            <RefreshButton {...refreshButtonProps} />
          </>
        )}
      >
        <Header record={record} />
        <Mid record={record} />
        <Description record={record} />
        <Footer record={record} />
      </Show>
    </CanAccess>
  );
};
