import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Select } from "antx";
import { CSSProperties } from "react";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  status?: string;
  style?: CSSProperties;
};

export const Status = ({ editable, status, style }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText title={translate("selects.treatment.title")} style={style}>
      {editable ? (
        <Select name="status" style={{ margin: "-5px" }}>
          <Select.Option value="PERFECT">
            {translate("selects.treatment.perfect")}
          </Select.Option>
          <Select.Option value="GOOD">
            {translate("selects.treatment.good")}
          </Select.Option>
          <Select.Option value="UNKNOWN">
            {translate("selects.treatment.unknown")}
          </Select.Option>
          <Select.Option value="BAD">
            {translate("selects.treatment.bad")}
          </Select.Option>
          <Select.Option value="WORST">
            {translate("selects.treatment.worst")}
          </Select.Option>
        </Select>
      ) : (
        <TextField
          value={
            status
              ? translate("selects.treatment." + String(status).toLowerCase())
              : "n.A."
          }
        />
      )}
    </HeaderText>
  );
};
