import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Select } from "antx";
import { CSSProperties } from "react";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  status?: string;
  style?: CSSProperties;
};

export const Status = ({ editable, status, style }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText title={translate("selects.payment.title")} style={style}>
      {editable ? (
        <Select name="status" style={{ margin: "-5px" }}>
          <Select.Option value="OPEN">
            {translate("selects.payment.open")}
          </Select.Option>
          <Select.Option value="PAID">
            {translate("selects.payment.paid")}
          </Select.Option>
        </Select>
      ) : (
        <TextField
          value={
            status
              ? translate("selects.payment." + String(status).toLowerCase())
              : "n.A."
          }
        />
      )}
    </HeaderText>
  );
};
