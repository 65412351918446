import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Select, Typography } from "antd";
import { useEffect, useState } from "react";

type Props = {
  value?: string;
  style?: React.CSSProperties;
  onChange?: (value: string) => void;
  editable?: boolean;
};

const { Text } = Typography;

export const FeeType = (props: Props) => {
  const translate = useTranslate();

  const [selected, setSelected] = useState(props.value);

  const onSelect = (value: string) => {
    setSelected(value);
    props.onChange && props.onChange(value);
  };

  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  return props.editable ? (
    <Select style={props.style} value={selected} onSelect={onSelect}>
      <Select.Option value="OWN_VALUE">
        {translate("selects.fee.own_value")}
      </Select.Option>
      <Select.Option value="FEE_MIN">
        {translate("selects.fee.fee_min")}
      </Select.Option>
      <Select.Option value="FEE_MAX">
        {translate("selects.fee.fee_max")}
      </Select.Option>
      <Select.Option value="FEE_THRESHOLD">
        {translate("selects.fee.fee_threshold")}
      </Select.Option>
      <Select.Option value="BAYERN_VALUE">
        {translate("selects.fee.bayern_value")}
      </Select.Option>
      <Select.Option value="BUND_VALUE">
        {translate("selects.fee.bund_value")}
      </Select.Option>
    </Select>
  ) : (
    <TextField
      value={translate("selects.fee." + String(props.value).toLowerCase())}
    />
  );
};
