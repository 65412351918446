import gql from "graphql-tag";

export const AUTH_REGISTER_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;

export const AUTH_LOGIN_MUTATION = gql`
  mutation SignInUser($input: SignInUserInput!) {
    signInUser(signInUserInput: $input) {
      access {
        token
        expire
      }
      refresh {
        token
        expire
      }
      user {
        id
        email
        name
        role
      }
    }
  }
`;

export const AUTH_LOGOUT_MUTATION = gql`
  mutation SignOutUser($input: SignOutUserInput!) {
    signOutUser(input: $input)
  }
`;

export const AUTH_REFRESH_MUTATION = gql`
  mutation RefreshUser($input: RefreshUserInput!) {
    refreshUser(input: $input) {
      access {
        token
        expire
      }
      refresh {
        token
        expire
      }
      user {
        id
        email
        name
        role
      }
    }
  }
`;
