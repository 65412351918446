import { BaseRecord, useTranslate } from "@refinedev/core";
import { Button, Form, Typography, Col, Divider, Row, Card, Input } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { updateFieldValue } from "../common/update-field-value";
import { Quantity, SearchService } from ".";

type Props = {
  editable?: boolean;
  positions?: BaseRecord[];
};

export const Positions = (props: Props) => {
  const translate = useTranslate();
  const form = useFormInstance();

  const onChangeService = (name: number, value?: BaseRecord) => {
    if (value) {
      updateFieldValue(form, ["positions", name, "serviceName"], value.name);
    }
  };

  return (
    <>
      <Divider>{translate("pages.treatment.fields.positions")}</Divider>
      <Card>
        <Row style={{ marginBottom: 8 }} gutter={[5, 0]}>
          <Col md={{ flex: "35%" }}>
            <Typography.Text>
              {translate("pages.treatment.fields.name")}
            </Typography.Text>
          </Col>
          <Col md={{ flex: props.editable ? "6%" : "7%" }}>
            <Typography.Text>
              {translate("pages.treatment.fields.quantity")}
            </Typography.Text>
          </Col>
          <Col md={{ flex: props.editable ? "55%" : "56%" }}>
            <Typography.Text>
              {translate("pages.treatment.fields.description")}
            </Typography.Text>
          </Col>
        </Row>
        {props.editable ? (
          <Form.List name="positions">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} style={{ marginBottom: 8 }} gutter={[5, 0]}>
                    <Col md={{ flex: "35%" }}>
                      <Form.Item {...restField} name={[name, "service"]}>
                        <SearchService
                          editable={props.editable}
                          onChange={(value) => onChangeService(name, value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ flex: "6%" }}>
                      <Form.Item {...restField} name={[name, "quantity"]}>
                        <Quantity editable={props.editable} />
                      </Form.Item>
                    </Col>
                    <Col md={{ flex: "56%" }}>
                      <Form.Item {...restField} name={[name, "description"]}>
                        <Input.TextArea autoSize={{ minRows: 1 }} />
                      </Form.Item>
                    </Col>
                    <Col md={{ flex: "2%" }}>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add({
                        quantity: 1,
                      });
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    {translate("buttons.add")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        ) : (
          props.positions?.map((position, key) => (
            <Row key={key} style={{ marginBottom: 8 }} gutter={[5, 0]}>
              <Col md={{ flex: "35%" }}>
                <Typography.Text>{position.serviceName}</Typography.Text>
              </Col>
              <Col md={{ flex: "7%" }}>
                <Typography.Text>{position.quantity}</Typography.Text>
              </Col>
              <Col md={{ flex: "58%" }}>
                <Typography.Text>{position.description}</Typography.Text>
              </Col>
            </Row>
          ))
        )}
      </Card>
    </>
  );
};
