import { InputNumber } from "antd";
import { useEffect, useState } from "react";

type Props = {
  editable?: boolean;
  value?: string;
  onChange?: (value: number) => void;
};

export const Quantity = (props: Props) => {
  const [value, setValue] = useState(Number(props.value));

  const onChange = (quantity: number | null) => {
    setValue(quantity === null ? 1 : quantity);
    props.onChange && props.onChange(quantity === null ? 1 : quantity);
  };

  useEffect(() => {
    setValue(Number(props.value));
  }, [props.value]);

  return props.editable ? (
    <InputNumber min={1} value={value} onChange={onChange} />
  ) : (
    <span>{Number(value)}</span>
  );
};
