import { TextField } from "@refinedev/antd";
import { Row, Col } from "antd";
import { useTranslate } from "@refinedev/core";
import { Input } from "antx";
import { HeaderText } from "../common";

type Props = {
  editable?: boolean;
  record?: any;
};

export const Footer = ({ editable, record }: Props) => {
  const translate = useTranslate();

  return (
    <Row>
      <Col md={4}>
        <HeaderText title={translate("pages.service.fields.own_value")}>
          {editable ? (
            <Input
              type="number"
              name="ownValue"
              getValueProps={(value) => ({ value: Number(value).toFixed(2) })}
              getValueFromEvent={(e) =>
                isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
              }
              suffix="€"
              min={0}
              step={0.01}
              style={{ margin: 0 }}
            />
          ) : (
            <TextField
              value={Number(record?.ownValue ?? 0).toFixed(2) + " €"}
            />
          )}
        </HeaderText>
      </Col>
      <Col md={4}>
        <HeaderText title={translate("pages.service.fields.fee_threshold")}>
          {editable ? (
            <Input
              type="number"
              name="feeThreshold"
              getValueProps={(value) => ({ value: Number(value).toFixed(2) })}
              getValueFromEvent={(e) =>
                isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
              }
              suffix="€"
              min={0}
              step={0.01}
              style={{ margin: 0 }}
            />
          ) : (
            <TextField
              value={Number(record?.feeThreshold ?? 0).toFixed(2) + " €"}
            />
          )}
        </HeaderText>
      </Col>
      <Col md={4}>
        <HeaderText title={translate("pages.service.fields.bayern_value")}>
          {editable ? (
            <Input
              type="number"
              name="bayernValue"
              getValueProps={(value) => ({ value: Number(value).toFixed(2) })}
              getValueFromEvent={(e) =>
                isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
              }
              suffix="€"
              min={0}
              step={0.01}
              style={{ margin: 0 }}
            />
          ) : (
            <TextField
              value={Number(record?.bayernValue ?? 0).toFixed(2) + " €"}
            />
          )}
        </HeaderText>
      </Col>
      <Col md={4}>
        <HeaderText title={translate("pages.service.fields.bund_value")}>
          {editable ? (
            <Input
              type="number"
              name="bundValue"
              getValueProps={(value) => ({ value: Number(value).toFixed(2) })}
              getValueFromEvent={(e) =>
                isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
              }
              suffix="€"
              min={0}
              step={0.01}
              style={{ margin: 0 }}
            />
          ) : (
            <TextField
              value={Number(record?.bundValue ?? 0).toFixed(2) + " €"}
            />
          )}
        </HeaderText>
      </Col>
      <Col md={4}>
        <HeaderText title={translate("pages.service.fields.fee_min")}>
          {editable ? (
            <Input
              type="number"
              name="feeMin"
              getValueProps={(value) => ({ value: Number(value).toFixed(2) })}
              getValueFromEvent={(e) =>
                isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
              }
              suffix="€"
              min={0}
              step={0.01}
              style={{ margin: 0 }}
            />
          ) : (
            <TextField value={Number(record?.feeMin ?? 0).toFixed(2) + " €"} />
          )}
        </HeaderText>
      </Col>
      <Col md={4}>
        <HeaderText title={translate("pages.service.fields.fee_max")}>
          {editable ? (
            <Input
              type="number"
              name="feeMax"
              getValueProps={(value) => ({ value: Number(value).toFixed(2) })}
              getValueFromEvent={(e) =>
                isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
              }
              suffix="€"
              min={0}
              step={0.01}
              style={{ margin: 0 }}
            />
          ) : (
            <TextField value={Number(record?.feeMax ?? 0).toFixed(2) + " €"} />
          )}
        </HeaderText>
      </Col>
    </Row>
  );
};
