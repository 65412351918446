import { DateField, useTable } from "@refinedev/antd";
import { BaseKey, useNavigation, useTranslate } from "@refinedev/core";
import { Table, Tag } from "antd";
import { TREATMENTS_LIST_QUERY } from "../../queries";
import { IListTreatment } from "../../types/patient";
import { EmptyData } from "../common";

type Props = {
  patientId?: BaseKey;
};

export const Treatments = ({ patientId }: Props) => {
  const translate = useTranslate();
  const { show } = useNavigation();

  const colorStatus: { [key: string]: string } = {
    PERFECT: "green",
    GOOD: "yellow",
    UNKNOWN: "cyan",
    BAD: "orange",
    WORST: "red",
  };

  const { tableProps } = useTable<IListTreatment>({
    resource: "treatments",
    meta: {
      gqlQuery: TREATMENTS_LIST_QUERY,
    },
    syncWithLocation: false,
    filters: {
      permanent: [
        {
          field: "id",
          operator: "eq",
          value: patientId,
        },
      ],
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    pagination: {
      mode: "server",
    },
  });

  return (
    <Table
      {...tableProps}
      rowKey="id"
      onRow={(record) => {
        return {
          onClick: () => record.id && show("treatment", record.id),
        };
      }}
      rowHoverable
      locale={{
        emptyText : <EmptyData text={translate("empty_data")} />
      }}
    >
      <Table.Column
        dataIndex="reference"
        title={translate("pages.treatment.fields.reference")}
        width="20%"
        sorter
      />
      <Table.Column
        title={translate("pages.treatment.fields.createdAt")}
        dataIndex="createdAt"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="20%"
        sorter
      />
      <Table.Column
        title={translate("pages.treatment.fields.completedOn")}
        dataIndex="completedOn"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="20%"
        sorter
      />
      <Table.Column
        title={translate("selects.treatment.title")}
        dataIndex="status"
        render={(value: any) => (
          <Tag color={colorStatus[value ?? "UNKNOWN"]}>
            {translate(
              "selects.treatment." + String(value ?? "UNKNOWN").toLowerCase()
            )}
          </Tag>
        )}
        width="40%"
      />
    </Table>
  );
};
