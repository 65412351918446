import { Document, Font, Page, Text, View, Image } from "@react-pdf/renderer";
import { Table, TR, TH, TD } from "@ag-media/react-pdf-table";
import dayjs from "dayjs";
import { BaseRecord } from "@refinedev/core";

type Props = {
  record?: BaseRecord;
  settings?: BaseRecord[];
  translate: any;
};

export const Invoice = ({ record, settings, translate }: Props) => {
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "/fonts/Roboto-Regular.ttf",
      },
      {
        src: "/fonts/Roboto-Bold.ttf",
        fontWeight: 600,
      },
    ],
  });

  const Logo = () => (
    <View
      style={{
        width: 120,
        alignSelf: "center",
      }}
    >
      <Image src="/images/logo.png" />
    </View>
  );

  const SenderLine = () => (
    <Text
      style={{ marginTop: 20, fontSize: 7, color: "grey", marginBottom: 5 }}
    >
      {settings?.find((x) => x.key === "companyName")?.value} -{" "}
      {settings?.find((x) => x.key === "companyStreet")?.value} -{" "}
      {settings?.find((x) => x.key === "companyPostcode")?.value}{" "}
      {settings?.find((x) => x.key === "companyCity")?.value}
    </Text>
  );

  const ReceiverAddress = () => (
    <View style={{ lineHeight: 1.3, fontSize: 10 }}>
      <Text>{record?.address?.name}</Text>
      <Text>{record?.address?.street}</Text>
      <Text>
        {record?.address?.postcode} {record?.address?.city}
      </Text>
      <Text>{record?.address?.country}</Text>
    </View>
  );

  const SenderAddress = () => (
    <View style={{ textAlign: "right", lineHeight: 1.3, fontSize: 10 }}>
      <Text>{settings?.find((x) => x.key === "companyName")?.value}</Text>
      <Text>{translate("pdf.job")}</Text>
      <Text>{settings?.find((x) => x.key === "companyStreet")?.value}</Text>
      <Text>
        {settings?.find((x) => x.key === "companyPostcode")?.value}{" "}
        {settings?.find((x) => x.key === "companyCity")?.value}
      </Text>
    </View>
  );

  const Data = () => (
    <View
      style={{
        textAlign: "right",
        lineHeight: 1.3,
        fontSize: 10,
        paddingTop: 20,
      }}
    >
      <Text>
        {translate("pdf.date")}: {dayjs(record?.createdAt).format("L")}
      </Text>
      <Text>
        {translate("pdf.reference")}: {record?.reference}
      </Text>
    </View>
  );

  const Title = () => (
    <Text style={{ paddingTop: 20, fontWeight: 600 }}>
      {translate("pages.invoice.titles.singular")}
    </Text>
  );

  const HeaderText = () => (
    <Text style={{ fontSize: 10, paddingTop: 10 }}>
      {record?.diagnoses
        ?.map((x: any) => (x.code && String(x.code) + " ") + x.description)
        .join("\r\n")}
    </Text>
  );

  const Positions = () => (
    <>
      <Table
        style={{ fontSize: 10, border: 0, paddingTop: 10 }}
        tdStyle={{ padding: 5 }}
      >
        <TH style={{ borderBottom: 1 }}>
          <TD weighting={0.1}>{translate("pdf.position")}</TD>
          <TD weighting={0.1}>{translate("pdf.number")}</TD>
          <TD weighting={0.4}>{translate("pdf.service")}</TD>
          <TD weighting={0.1} style={{ justifyContent: "flex-end" }}>
            {translate("pdf.quantity")}
          </TD>
          <TD weighting={0.15} style={{ justifyContent: "flex-end" }}>
            {translate("pdf.sum_one")}
          </TD>
          <TD weighting={0.15} style={{ justifyContent: "flex-end" }}>
            {translate("pdf.sum_all")}
          </TD>
        </TH>
        {record?.positions?.map((x: any, index: number) => (
          <TR key={index}>
            <TD weighting={0.1}>{index + 1}</TD>
            <TD weighting={0.1}>{x.service?.reference}</TD>
            <TD weighting={0.4}>{x.serviceName}</TD>
            <TD weighting={0.1} style={{ justifyContent: "flex-end" }}>
              {x.quantity}
            </TD>
            <TD weighting={0.15} style={{ justifyContent: "flex-end" }}>
              {Number(x.amountOne).toFixed(2) + " €"}
            </TD>
            <TD weighting={0.15} style={{ justifyContent: "flex-end" }}>
              {Number(x.amountSum).toFixed(2) + " €"}
            </TD>
          </TR>
        ))}
        <TR style={{ borderTop: 1 }}>
          <TD
            weighting={0.9}
            style={{ justifyContent: "flex-end", fontWeight: 600 }}
          >
            {translate("pdf.sum")}
          </TD>
          <TD style={{ justifyContent: "flex-end", fontWeight: 600 }}>
            {Number(record?.amount).toFixed(2) + " €"}
          </TD>
        </TR>
      </Table>
    </>
  );

  const FooterText = () => (
    <Text wrap style={{ fontSize: 10, paddingTop: 20 }}></Text>
  );

  const Footer = () => (
    <View
      style={{
        fontSize: 8,
        color: "grey",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 30,
        left: 50,
        right: 50,
      }}
      fixed
    >
      <Table style={{ border: 0 }} trStyle={{ height: 10 }}>
        <TR>
          <TD weighting={0.4}>
            {settings?.find((x) => x.key === "companyName")?.value}
          </TD>
          <TD>
            {translate("pdf.bank")}:{" "}
            {settings?.find((x) => x.key === "companyBank")?.value}
          </TD>
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pdf.tax")}:{" "}
            {settings?.find((x) => x.key === "companyTax")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            {settings?.find((x) => x.key === "companyStreet")?.value}
          </TD>
          <TD>
            {translate("pdf.iban")}:{" "}
            {settings?.find((x) => x.key === "companyIBAN")?.value}
          </TD>
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pdf.register")}:{" "}
            {settings?.find((x) => x.key === "companyRegister")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            {settings?.find((x) => x.key === "companyPostcode")?.value}{" "}
            {settings?.find((x) => x.key === "companyCity")?.value}
          </TD>
          <TD>
            {translate("pdf.bic")}:{" "}
            {settings?.find((x) => x.key === "companyBIC")?.value}
          </TD>
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pdf.court")}:{" "}
            {settings?.find((x) => x.key === "companyCourt")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            Tel: {settings?.find((x) => x.key === "companyTel")?.value}
          </TD>
          <TD />
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pdf.owner")}:{" "}
            {settings?.find((x) => x.key === "companyOwner")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            Fax: {settings?.find((x) => x.key === "companyTax")?.value}
          </TD>
          <TD />
          <TD style={{ justifyContent: "flex-end" }}>
            {translate("pdf.url")}:{" "}
            {settings?.find((x) => x.key === "companyUrl")?.value}
          </TD>
        </TR>
        <TR>
          <TD weighting={0.4}>
            {settings?.find((x) => x.key === "companyEmail")?.value}
          </TD>
          <TD />
          <TD />
        </TR>
      </Table>
    </View>
  );

  const PageNumber = () => (
    <Text
      style={{ fontSize: 10, position: "absolute", bottom: 10, right: 10 }}
      render={({ pageNumber, totalPages }) =>
        translate("pdf.pageOf", { current: pageNumber, last: totalPages })
      }
      fixed
    />
  );

  return (
    <Document>
      <Page
        size="A4"
        style={{
          padding: 50,
          paddingTop: 20,
          paddingBottom: 120,
          fontFamily: "Roboto",
        }}
      >
        <Logo />
        <SenderLine />
        <ReceiverAddress />
        <SenderAddress />
        <Data />
        <Title />
        <HeaderText />
        <Positions />
        <FooterText />
        <Footer />
        <PageNumber />
      </Page>
    </Document>
  );
};
