import {
  DeleteButton,
  EditButton,
  ListButton,
  RefreshButton,
  Show,
} from "@refinedev/antd";
import { CanAccess, useList, useShow, useTranslate } from "@refinedev/core";
import {
  SETTINGS_LIST_QUERY,
  TREATMENT_DELETE_MUTATION,
  TREATMENT_SHOW_QUERY,
} from "../../queries";
import {
  General,
  Diagnose,
  Treatment,
  Results,
  Positions,
} from "../../components/treatment";
import { Unauthorized } from "../../components/common";
import { Treatment as TreatmentPDF } from "../../components/pdf/treatment";
import { BlobProvider } from "@react-pdf/renderer";
import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const TreatmentShow = () => {
  const translate = useTranslate();
  const { query } = useShow({
    meta: {
      gqlQuery: TREATMENT_SHOW_QUERY,
    },
  });
  const { data, isLoading } = query;

  const record = data?.data;

  const { data: dataSettings } = useList({
    resource: "settings",
    meta: {
      gqlQuery: SETTINGS_LIST_QUERY,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Show
        isLoading={isLoading}
        breadcrumb={false}
        title={translate("pages.treatment.titles.show")}
        headerButtons={({
          deleteButtonProps,
          editButtonProps,
          listButtonProps,
          refreshButtonProps,
        }) => (
          <>
            {dataSettings?.data && (
              <BlobProvider
                document={
                  <TreatmentPDF
                    translate={translate}
                    record={record}
                    settings={dataSettings.data}
                  />
                }
              >
                {({ url }) => (
                  <Button
                    icon={<PrinterOutlined />}
                    onClick={() => url && window.open(url, "_blank")?.focus()}
                  >
                    {translate("buttons.print")}
                  </Button>
                )}
              </BlobProvider>
            )}
            {listButtonProps && <ListButton {...listButtonProps} />}
            {editButtonProps && <EditButton {...editButtonProps} />}
            {deleteButtonProps && (
              <DeleteButton
                {...deleteButtonProps}
                meta={{ gqlMutation: TREATMENT_DELETE_MUTATION }}
              />
            )}
            <RefreshButton {...refreshButtonProps} />
          </>
        )}
      >
        <General record={record} />
        <Diagnose
          diagnoses={record?.diagnoses}
          style={{ minHeight: "150px" }}
        />
        <Treatment treatment={record?.treatment} />
        <Results results={record?.results} />
        <Positions positions={record?.positions} />
      </Show>
    </CanAccess>
  );
};
