import { Action, Authenticated, IResourceItem, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
  ErrorComponent,
  ThemedLayoutV2,
  ThemedSiderV2,
  ThemedTitleV2,
  useNotificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { App as AntdApp, Image } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/common";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { data } from "./provider";
import { useTranslation } from "react-i18next";
import { AccountRoute } from "./pages/account";
import { PatientProps, PatientRoute } from "./pages/patient";
import { TreatmentProps, TreatmentRoute } from "./pages/treatment";
import { InvoiceProps, InvoiceRoute } from "./pages/invoice";
import { authProvider } from "./provider/auth-provider";
import { SettingProps, SettingRoute } from "./pages/setting";
import { FileProps, FileRoute } from "./pages/file";
import { ReceiptProps, ReceiptRoute } from "./pages/receipt";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const handleSiteTile = (options: {
    resource?: IResourceItem | undefined;
    action?: Action | undefined;
    params?: Record<string, string | undefined> | undefined;
    pathname?: string | undefined;
    autoGeneratedTitle: string;
  }): string => {
    switch (options.action) {
      case "list":
        return (
          t(`pages.${options.resource?.name}.titles.plural`) +
          "  | Natura Heilpraxis"
        );
      case "show":
        return `${t(`pages.${options.resource?.name}.titles.show`)} ${
          options.params?.id
        } | Natura Heilpraxis`;
      case "edit":
        return `${t(`pages.${options.resource?.name}.titles.singular`)} ${
          options.params?.id
        } | Natura Heilpraxis`;
      case "create":
        return `${t(
          `pages.${options.resource?.name}.titles.create`
        )} | Natura Heilpraxis
                      `;
      default:
        return "Natura Heilpraxis";
    }
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <AntdApp>
            <Refine
              dataProvider={data}
              /* liveProvider={live} */
              notificationProvider={useNotificationProvider}
              routerProvider={routerBindings}
              authProvider={authProvider}
              i18nProvider={i18nProvider}
              resources={[
                PatientProps,
                TreatmentProps,
                InvoiceProps,
                ReceiptProps,
                FileProps,
                ...SettingProps,
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                useNewQueryKeys: true,
                projectId: "AoihRH-IKE0lP-QaskY5",
                liveMode: "auto",
                redirect: {
                  afterCreate: "show",
                  afterEdit: "show",
                },
                textTransformers: {
                  singular(word) {
                    return t(`pages.${word.toLowerCase()}.titles.singular`);
                  },
                  plural(word) {
                    return t(`pages.${word.toLowerCase()}.titles.plural`);
                  },
                },
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated
                      key="authenticated-inner"
                      fallback={<CatchAllNavigate to="/login" />}
                    >
                      <ThemedLayoutV2
                        Header={Header}
                        Sider={(props) => <ThemedSiderV2 {...props} fixed />}
                        Title={({ collapsed }) => (
                          <ThemedTitleV2
                            collapsed={collapsed}
                            text="Natura Heilpraxis"
                            icon={
                              <Image preview={false} src="/images/logo.png" />
                            }
                          />
                        )}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={<NavigateToResource resource="patient" />}
                  />
                  {PatientRoute()}
                  {TreatmentRoute()}
                  {InvoiceRoute()}
                  {FileRoute()}
                  {ReceiptRoute()}
                  {SettingRoute()}
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
                <Route
                  element={
                    <Authenticated
                      key="authenticated-outer"
                      fallback={<Outlet />}
                    >
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  {AccountRoute()}
                </Route>
              </Routes>
              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler handler={handleSiteTile} />
            </Refine>
          </AntdApp>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
};

export default App;
