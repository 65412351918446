import { Flex, Typography } from "antd";
import { HeaderText } from "../common";
import { BaseKey, useTranslate } from "@refinedev/core";
import { Input, TextArea, Watch } from "antx";
import style from "antd/es/affix/style";
import { Name } from "./name";

type Props = {
  fileName?: string;
  patientName?: string;
  patientId?: BaseKey;
  createdAt?: string;
  size?: number;
  description?: string;
  editable?: boolean;
};

export const Data = ({
  fileName,
  patientName,
  patientId,
  createdAt,
  size,
  description,
  editable,
}: Props) => {
  const translate = useTranslate();
  const humanFileSize = (bytes: number, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };

  return (
    <Flex vertical>
      <HeaderText title={translate("pages.file.fields.fileName")}>
        {editable ? (
          <Input
            name="name"
            placeholder={translate("pages.file.placeholders.fileName")}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: translate("validations.empty_file"),
              },
            ]}
          >
            {fileName}
          </Input>
        ) : (
          <Typography.Text>{fileName}</Typography.Text>
        )}
      </HeaderText>
      {!editable && (
        <HeaderText title={translate("pages.file.fields.createdAt")}>
          <Typography.Text>
            {new Date(createdAt ?? "").toLocaleDateString()}
          </Typography.Text>
        </HeaderText>
      )}
      <Name editable={editable} id={patientId} name={patientName} />
      <HeaderText title={translate("pages.file.fields.size")}>
        {editable ? (
          <Watch name="size">
            {(size) => (
              <Typography.Text>
                {humanFileSize(size ?? 0, true)}
              </Typography.Text>
            )}
          </Watch>
        ) : (
          <Typography.Text>{humanFileSize(size ?? 0, true)}</Typography.Text>
        )}
      </HeaderText>
      <HeaderText title={translate("pages.file.fields.description")}>
        {editable ? (
          <TextArea
            style={{ margin: 0 }}
            autoSize={{ minRows: 10 }}
            name="description"
          />
        ) : (
          <Typography.Text>{description}</Typography.Text>
        )}
      </HeaderText>
    </Flex>
  );
};
