import { Route } from "react-router-dom";
import { ForgotPassword, Login, Register } from ".";

export const AccountRoute = () => (
  <>
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
  </>
);
