import { CanParams, IAccessControlContext } from "@refinedev/core";
import { CanResponse } from "@refinedev/core/dist/contexts/accessControl/types";
import { StringAdapter, newEnforcer, newModel } from "casbin";
import { authProvider } from ".";

export const accessProvider: IAccessControlContext = {
  can: async ({
    resource,
    action,
    params,
  }: CanParams): Promise<CanResponse> => {
    const enforcer = await newEnforcer(model, adapter);
    const can = await enforcer.enforce(
      await authProvider.getPermissions?.(),
      resource,
      action
    );

    return { can };
  },
  options: {
    buttons: {
      enableAccessControl: true,
      hideIfUnauthorized: false,
    },
  },
};

const model = newModel(`
  [request_definition]
  r = sub, obj, act
  
  [policy_definition]
  p = sub, obj, act
  
  [role_definition]
  g = _, _
  
  [policy_effect]
  e = some(where (p.eft == allow))
  
  [matchers]
  m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
  `);

const adapter = new StringAdapter(`
  p, user, patient, (list)|(show)|(edit)|(delete)|(create)
  p, user, invoice, (list)|(show)|(edit)|(delete)|(create)
  p, user, treatment, (list)|(show)|(edit)|(delete)|(create)
  p, user, service, (list)|(show)|(edit)|(delete)|(create)

  p, admin, patient, (list)|(show)|(edit)|(delete)|(create)
  p, admin, invoice, (list)|(show)|(edit)|(delete)|(create)
  p, admin, treatment, (list)|(show)|(edit)|(delete)|(create)
  p, admin, service, (list)|(show)|(edit)|(delete)|(create)
  p, admin, setting, (list)|(show)|(edit)|(delete)|(create)
  p, admin, general, (list)|(show)|(edit)|(delete)|(create)
  p, admin, user, (list)|(show)|(edit)|(delete)|(create)
  p, admin, template, (list)|(show)|(edit)|(delete)|(create)
  `);
