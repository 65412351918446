import { useTranslate } from "@refinedev/core";
import { Divider, Typography } from "antd";
import { InputNumber } from "antx";

type Props = {
  amount?: number;
  editable?: boolean;
};

export const Brutto = (props: Props) => {
  const translate = useTranslate();

  return (
    <>
      <Divider />
      <Typography.Title level={5} style={{ textAlign: "left" }}>
        {translate("pages.invoice.fields.amount_brutto")}
      </Typography.Title>
      {props.editable ? (
        <InputNumber
          name="amount"
          style={{ alignSelf: "end", maxWidth: "130px" }}
          addonAfter="€"
          precision={2}
        />
      ) : (
        <span style={{ alignSelf: "end" }}>
          {Number(props.amount ?? 0).toFixed(2) + " €"}
        </span>
      )}
    </>
  );
};
