import { TextField } from "@refinedev/antd";
import { CrudFilters, useList, useTranslate } from "@refinedev/core";
import { Flex, Form, Select } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import { Watch } from "antx";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { updateFieldValue } from "../common/update-field-value";
import { ADDRESS_LIST_QUERY } from "../../queries";
import { HeaderText } from "../common";

type Props = {
  address?: any;
  style?: CSSProperties;
  editable?: boolean;
};

export const Address = ({ address, style, editable }: Props) => {
  const translate = useTranslate();
  const form = useFormInstance();

  const [filters, setFilters] = useState<CrudFilters>();
  let filteredId = 0;

  const { data } = useList({
    resource: "Address",
    filters: filters,
    meta: {
      gqlQuery: ADDRESS_LIST_QUERY,
    },
  });

  const setId = (id: number) => {
    if (filteredId !== id) {
      filteredId = id;
      setFilters([
        {
          operator: "eq",
          field: "id",
          value: filteredId,
        },
      ]);
    }
  };

  useEffect(() => {
    if (data?.data[0] && form) {
      updateFieldValue(form, "address", data.data[0]);
    }
  }, [data]);

  return (
    <HeaderText title={translate("pages.invoice.fields.address")} style={style}>
      {editable ? (
        <>
          <Watch onChange={(next: any) => setId(next?.id)} name="patient" />
          <Form.Item
            name={["address"]}
            getValueFromEvent={(_, item) => JSON.parse(item.label)}
            getValueProps={(value) => ({
              value: value?.id,
              label: JSON.stringify(value),
            })}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              style={{
                height: "100px",
                marginBottom: "-25px",
                marginTop: "-5px",
              }}
              options={data?.data.map((x) => ({
                label: JSON.stringify(x),
                value: x.id,
              }))}
              labelRender={(x: any) => {
                let item = JSON.parse(x?.label ?? "{}");
                return (
                  <Flex vertical>
                    <TextField value={item?.name ?? "n.A."} />
                    <TextField value={item?.street ?? "n.A."} />
                    <Flex gap={4}>
                      <TextField value={item?.postcode ?? "n.A."} />
                      <TextField value={item?.city ?? "n.A."} />
                    </Flex>
                    <TextField value={item?.country ?? "n.A."} />
                  </Flex>
                );
              }}
              optionRender={(x: any) => {
                let item = JSON.parse(x?.label ?? "{}");
                return (
                  <Flex vertical>
                    <TextField value={item?.name ?? "n.A."} />
                    <TextField value={item?.street ?? "n.A."} />
                    <Flex gap={4}>
                      <TextField value={item?.postcode ?? "n.A."} />
                      <TextField value={item?.city ?? "n.A."} />
                    </Flex>
                    <TextField value={item?.country ?? "n.A."} />
                  </Flex>
                );
              }}
            />
          </Form.Item>
        </>
      ) : (
        <>
          <TextField value={address?.name ?? "n.A."} />
          <TextField value={address?.street ?? "n.A."} />
          <Flex gap={4}>
            <TextField value={address?.postcode ?? "n.A."} />
            <TextField value={address?.city ?? "n.A."} />
          </Flex>
          <TextField value={address?.country ?? "n.A."} />
        </>
      )}
    </HeaderText>
  );
};
