import { Route } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";
import {
  TreatmentCreate,
  TreatmentEdit,
  TreatmentShow,
  TreatmentsList,
} from ".";
import { CanAccess } from "@refinedev/core";
import { Unauthorized } from "../../components/common";

export const TreatmentRoute = () => (
  <Route path="/treatment">
    <Route
      index
      element={
        <CanAccess fallback={<Unauthorized />}>
          <TreatmentsList />
        </CanAccess>
      }
    />
    <Route
      path="create/:patient?"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <TreatmentCreate />
        </CanAccess>
      }
    />
    <Route
      path="edit/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <TreatmentEdit />
        </CanAccess>
      }
    />
    <Route
      path="show/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <TreatmentShow />
        </CanAccess>
      }
    />
  </Route>
);

export const TreatmentProps = {
  name: "treatment",
  list: "/treatment",
  create: "/treatment/create",
  edit: "/treatment/edit/:id",
  show: "/treatment/show/:id",
  meta: {
    canDelete: true,
    icon: <FileTextOutlined />,
  },
};
