import { DateField, TextField, useTable } from "@refinedev/antd";
import {
  BaseKey,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Table } from "antd";
import { FILES_LIST_QUERY } from "../../queries";
import { IListDocument } from "../../types/patient";
import { EmptyData } from "../common";

type Props = {
  patientId?: BaseKey;
};

export const Files = ({ patientId }: Props) => {
  const translate = useTranslate();
  const { show } = useNavigation();

  const { tableProps } = useTable<IListDocument>({
    resource: "files",
    meta: {
      gqlQuery: FILES_LIST_QUERY,
    },
    syncWithLocation: false,
    filters: {
      permanent: [
        {
          field: "id",
          operator: "eq",
          value: patientId,
        },
      ],
    },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    pagination: {
      mode: "server",
    },
  });

  const humanFileSize = (bytes: number, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };

  return (
    <Table
      {...tableProps}
      rowKey="id"
      onRow={(record, _) => {
        return {
          onClick: () => record.id && show("file", record.id),
        };
      }}
      rowHoverable
      locale={{
        emptyText : <EmptyData text={translate("empty_data")} />
      }}
    >
      <Table.Column
        title={translate("pages.file.fields.createdAt")}
        dataIndex="createdAt"
        render={(value: any) => <DateField value={value} locales="de" />}
        width="10%"
        sorter
      />
      <Table.Column
        dataIndex="name"
        title={translate("pages.file.fields.fileName")}
        sorter
      />
      <Table.Column
        dataIndex="size"
        title={translate("pages.file.fields.size")}
        render={(value: any) => <TextField value={humanFileSize(value)} />}
        width="10%"
        align="right"
        sorter
      />
    </Table>
  );
};
