import { BaseRecord, useTranslate } from "@refinedev/core";
import { Divider, Form, List } from "antd";
import { CSSProperties } from "react";
import { HeaderText } from "../common";
import { SearchDiagnose } from ".";

type Props = {
  style?: CSSProperties;
  diagnoses?: BaseRecord[];
  editable?: boolean;
};

export const Diagnose = ({ style, diagnoses, editable }: Props) => {
  const translate = useTranslate();

  const printDiagnoses = () => {
    if (diagnoses) {
      return (
        <List>
          {diagnoses.map((x) => (
            <List.Item
              key={x.id}
              style={{ border: "none", paddingTop: 1, paddingBottom: 1 }}
            >
              {String(x.code)} {x.description}
            </List.Item>
          ))}
        </List>
      );
    } else {
      return "n.A.";
    }
  };

  return (
    <>
      <Divider>{translate("pages.invoice.fields.medication")}</Divider>
      <HeaderText
        title={translate("pages.invoice.fields.diagnose")}
        style={style}
      >
        {editable ? (
          <Form.Item name="diagnoses">
            <SearchDiagnose
              style={{
                margin: "-10px",
              }}
            />
          </Form.Item>
        ) : (
          printDiagnoses()
        )}
      </HeaderText>
    </>
  );
};
