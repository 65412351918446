import { BaseKey, useTranslate } from "@refinedev/core";
import { Divider, Tabs, TabsProps } from "antd";
import { Files, Invoices, Receipts, Treatments } from ".";

type Props = {
  patientId: BaseKey | undefined;
};

export const Additional = ({ patientId }: Props) => {
  const translate = useTranslate();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: translate("titles.invoices"),
      children: <Invoices patientId={patientId} />,
    },
    {
      key: "2",
      label: translate("titles.treatments"),
      children: <Treatments patientId={patientId} />,
    },
    {
      key: "3",
      label: translate("titles.files"),
      children: <Files patientId={patientId}  />,
    },
    {
      key: "4",
      label: translate("titles.receipts"),
      children: <Receipts patientId={patientId}  />,
    },
  ];

  return (
    <>
      <Divider style={{ paddingTop: "20px" }}>
        {translate("pages.patient.fields.additional")}
      </Divider>
      <Tabs
        defaultActiveKey="1"
        items={items}
        centered
        indicator={{ size: (origin) => origin - 20, align: "center" }}
        size="large"
      />
    </>
  );
};
