import {
  BaseRecord,
  CrudFilters,
  useList,
  useTranslate,
} from "@refinedev/core";
import { CSSProperties, useEffect, useState } from "react";
import { GroupOption, SearchTagsAutocomplete } from "../common";
import { ICD_SEARCH_QUERY } from "../../queries";
import { Typography } from "antd";

type Props = {
  style?: CSSProperties;
  value?: BaseRecord[];
  onChange?: (value: BaseRecord[]) => void;
};

export const SearchDiagnose = ({ style, value, onChange }: Props) => {
  const translate = useTranslate();

  const [tags, setTags] = useState<BaseRecord[]>(value ?? []);
  const [options, setOptions] = useState<GroupOption[]>([]);
  const [filters, setFilters] = useState<CrudFilters>([
    {
      field: "id",
      operator: "nin",
      value: tags.map((x) => x.icdEntry.id),
    },
  ]);

  const { data } = useList({
    resource: "icds",
    meta: {
      gqlQuery: ICD_SEARCH_QUERY,
    },
    filters: filters,
    pagination: {
      pageSize: 20,
      mode: "server",
    },
  });

  const groupItemBy = (array: any, property: string) => {
    let hash: any = {},
      props = property.split(".");
    for (let i = 0; i < array.length; i++) {
      let key = props.reduce(function (acc: any, prop: any) {
        return acc && acc[prop];
      }, array[i]);
      if (!hash[key]) hash[key] = [];
      hash[key].push(array[i]);
    }
    return hash;
  };

  const onAdd = async (add?: BaseRecord) => {
    if (add && !tags.some((x) => x.icdEntry.id === add.id)) {
      onChange &&
        onChange([
          ...tags,
          {
            code: add.code,
            description: add.description,
            icdEntry: {
              id: add.id,
            },
          },
        ]);
      setTags((prev) => {
        let val = [
          ...prev,
          {
            code: add.code,
            description: add.description,
            icdEntry: {
              id: add.id,
            },
          },
        ];
        setFilters([
          {
            field: "id",
            operator: "nin",
            value: val.map((x) => x.icdEntry.id),
          },
        ]);
        return val;
      });
    }
  };

  const onRemove = (remove: BaseRecord) => {
    onChange &&
      onChange(
        tags.filter(
          (x) =>
            String(x.code).toLowerCase() !== String(remove.code).toLowerCase()
        )
      );
    setTags((prev) => {
      let val = prev.filter(
        (x) =>
          String(x.code).toLowerCase() !== String(remove.code).toLowerCase()
      );
      setFilters([
        {
          field: "id",
          operator: "nin",
          value: val.map((x) => x.icdEntry.id),
        },
      ]);
      return val;
    });
  };

  useEffect(() => {
    if (data?.data) {
      setOptions(
        Object.values(groupItemBy(data.data, "icdCategory.id")).map(
          (x: any) => {
            return {
              label: `${x[0].icdCategory.description} (${x[0].icdCategory.start} - ${x[0].icdCategory.end})`,
              options: x.map((y: any) => ({
                item: y,
                label: (
                  <span>
                    <b>{String(y.code)}</b> {y.description}
                  </span>
                ),
                value: y.id.toString(),
              })),
            };
          }
        )
      );
    }
  }, [data]);

  return (
    <SearchTagsAutocomplete
      addNewLabel={translate("pages.treatment.label.new_diagnose")}
      style={style}
      placeholder={translate("pages.treatment.placeholders.diagnose")}
      onAdd={onAdd}
      onRemove={onRemove}
      tags={tags}
      options={options}
      onSearch={(input) =>
        setFilters([
          {
            field: "name",
            operator: "contains",
            value: input,
          },
          {
            field: "id",
            operator: "nin",
            value: tags.map((x) => x.icdEntry.id),
          },
        ])
      }
      renderTag={(item) => (
        <Typography.Text
          style={{
            whiteSpace: "break-spaces",
            wordBreak: "break-word",
            fontSize: "13px",
            paddingLeft: "5px",
          }}
        >
          <b>{item.code + " "}</b>
          {item.description}
        </Typography.Text>
      )}
    />
  );
};
