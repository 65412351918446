import { EuroCircleOutlined } from "@ant-design/icons";
import { Route } from "react-router-dom";
import { FileCreate, FileShow, FilesList } from ".";
import { CanAccess } from "@refinedev/core";
import { Unauthorized } from "../../components/common";

export const FileRoute = () => (
  <Route path="/file">
    <Route
      index
      element={
        <CanAccess fallback={<Unauthorized />}>
          <FilesList />
        </CanAccess>
      }
    />
    <Route
      path="create/:patient?"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <FileCreate />
        </CanAccess>
      }
    />
    <Route
      path="show/:id"
      element={
        <CanAccess fallback={<Unauthorized />}>
          <FileShow />
        </CanAccess>
      }
    />
  </Route>
);

export const FileProps = {
  name: "file",
  list: "/file",
  create: "/file/create",
  show: "/file/show/:id",
  meta: {
    canDelete: true,
    icon: <EuroCircleOutlined />,
  },
};
