import { BaseRecord, useTranslate } from "@refinedev/core";
import { Button, Form, Typography, Col, Divider, Row, Card } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { InputNumber } from "antx";
import { updateFieldValue } from "../common/update-field-value";
import { FeeType, Quantity, SearchService } from ".";

type Props = {
  editable?: boolean;
  positions?: BaseRecord[];
};

export const Positions = (props: Props) => {
  const translate = useTranslate();
  const form = useFormInstance();

  const calculateAmount = () => {
    let positions = form.getFieldValue("positions");
    let amount: number = 0;

    for (let position of positions) {
      if (position.serviceName) {
        amount += Number(position.amountSum);
      }
    }

    updateFieldValue(form, "amount", amount);
  };

  const onChangeQuantity = (name: number, value: number) => {
    let amountOne = form.getFieldValue(["positions", name, "amountOne"]);
    updateFieldValue(form, ["positions", name, "amountSum"], amountOne * value);
    calculateAmount();
  };

  const onChangeFeeType = (name: number, value: string) => {
    let service = form.getFieldValue(["positions", name, "service"]);

    let amountOne = 0;
    switch (value) {
      case "OWN_VALUE":
        amountOne = service?.ownValue;
        break;
      case "FEE_THRESHOLD":
        amountOne = service?.feeThreshold;
        break;
      case "FEE_MIN":
        amountOne = service?.feeMin;
        break;
      case "FEE_MAX":
        amountOne = service?.feeMax;
        break;
      case "BAYERN_VALUE":
        amountOne = service?.bayernValue;
        break;
      case "BUND_VALUE":
        amountOne = service?.bundValue;
        break;
      default:
        break;
    }

    let quantity = form.getFieldValue(["positions", name, "quantity"]);
    updateFieldValue(form, ["positions", name, "amountOne"], amountOne);
    updateFieldValue(
      form,
      ["positions", name, "amountSum"],
      amountOne * quantity
    );
    calculateAmount();
  };

  const onChangeService = (name: number, value?: BaseRecord) => {
    if (!value) {
      updateFieldValue(form, ["positions", name, "amountOne"], 0);
      updateFieldValue(form, ["positions", name, "amountSum"], 0);
    } else {
      let feeType = form.getFieldValue(["positions", name, "feeType"]);
      let amountOne = 0;
      switch (feeType) {
        case "OWN_VALUE":
          amountOne = value?.ownValue;
          break;
        case "FEE_THRESHOLD":
          amountOne = value?.feeThreshold;
          break;
        case "FEE_MIN":
          amountOne = value?.feeMin;
          break;
        case "FEE_MAX":
          amountOne = value?.feeMax;
          break;
        case "BAYERN_VALUE":
          amountOne = value?.bayernValue;
          break;
        case "BUND_VALUE":
          amountOne = value?.bundValue;
          break;
        default:
          break;
      }

      let quantity = form.getFieldValue(["positions", name, "quantity"]);
      updateFieldValue(form, ["positions", name, "amountOne"], amountOne);
      updateFieldValue(
        form,
        ["positions", name, "amountSum"],
        amountOne * quantity
      );
      updateFieldValue(form, ["positions", name, "serviceName"], value.name);
    }
    calculateAmount();
  };

  const onChangeAmountOne = (name: number, value: number) => {
    let quantity = form.getFieldValue(["positions", name, "quantity"]);
    updateFieldValue(form, ["positions", name, "amountSum"], value * quantity);
    calculateAmount();
  };

  const onChangeAmountSum = (name: number, value: number) => {
    calculateAmount();
  };

  return (
    <>
      <Divider>{translate("pages.invoice.fields.positions")}</Divider>
      <Card>
        <Row style={{ marginBottom: 8 }} gutter={[5, 0]}>
          <Col md={{ flex: "35%" }}>
            <Typography.Text>
              {translate("pages.invoice.fields.name")}
            </Typography.Text>
          </Col>
          <Col md={{ flex: "20%" }}>
            <Typography.Text>{translate("selects.fee.title")}</Typography.Text>
          </Col>
          <Col md={{ flex: "10%" }}>
            <Typography.Text>
              {translate("pages.invoice.fields.quantity")}
            </Typography.Text>
          </Col>
          <Col md={{ flex: "15%" }}>
            <Typography.Text>
              {translate("pages.invoice.fields.amount_one")}
            </Typography.Text>
          </Col>
          <Col md={{ flex: "15%" }}>
            <Typography.Text>
              {translate("pages.invoice.fields.amount_sum")}
            </Typography.Text>
          </Col>
        </Row>
        {props.editable ? (
          <Form.List name="positions">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} style={{ marginBottom: 8 }} gutter={[5, 0]}>
                    <Col md={{ flex: "35%" }}>
                      <Form.Item {...restField} name={[name, "service"]}>
                        <SearchService
                          editable={props.editable}
                          onChange={(value) => onChangeService(name, value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ flex: "20%" }}>
                      <Form.Item {...restField} name={[name, "feeType"]}>
                        <FeeType
                          editable={props.editable}
                          onChange={(value) => onChangeFeeType(name, value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ flex: "10%" }}>
                      <Form.Item {...restField} name={[name, "quantity"]}>
                        <Quantity
                          editable={props.editable}
                          onChange={(value) => onChangeQuantity(name, value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ flex: "15%" }}>
                      <InputNumber
                        name={[name, "amountOne"]}
                        addonAfter="€"
                        precision={2}
                        onChange={(value) =>
                          onChangeAmountOne(name, Number(value))
                        }
                      />
                    </Col>
                    <Col md={{ flex: "15%" }}>
                      <InputNumber
                        name={[name, "amountSum"]}
                        addonAfter="€"
                        precision={2}
                        onChange={(value) =>
                          onChangeAmountSum(name, Number(value))
                        }
                      />
                    </Col>
                    <Col md={{ flex: "5%" }}>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          remove(name);
                          calculateAmount();
                        }}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add({
                        quantity: 1,
                        amountOne: 0,
                        amountSum: 0,
                        feeType: "OWN_VALUE",
                      });
                      calculateAmount();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    {translate("buttons.add")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        ) : (
          props.positions?.map((position, key) => (
            <Row key={key} style={{ marginBottom: 8 }} gutter={[5, 0]}>
              <Col md={{ flex: "35%" }}>
                <Typography.Text>{position.serviceName}</Typography.Text>
              </Col>
              <Col md={{ flex: "20%" }}>
                <Typography.Text>
                  {translate("selects.fee." + position.feeType.toLowerCase())}
                </Typography.Text>
              </Col>
              <Col md={{ flex: "10%" }}>
                <Typography.Text>{position.quantity}</Typography.Text>
              </Col>
              <Col md={{ flex: "15%" }}>
                <Typography.Text>
                  {Number(position.amountOne).toFixed(2) + " €"}
                </Typography.Text>
              </Col>
              <Col md={{ flex: "15%" }}>
                <Typography.Text>
                  {Number(position.amountSum).toFixed(2) + " €"}
                </Typography.Text>
              </Col>
            </Row>
          ))
        )}
      </Card>
    </>
  );
};
