import { Route } from "react-router-dom";
import { BookOutlined } from "@ant-design/icons";
import { ServiceCreate, ServiceEdit, ServiceShow, ServicesList } from ".";

export const ServiceRoute = () => (
  <Route path="service">
    <Route index element={<ServicesList />} />
    <Route path="show/:id" element={<ServiceShow />} />
    <Route path="edit/:id" element={<ServiceEdit />} />
    <Route path="create" element={<ServiceCreate />} />
  </Route>
);

export const ServiceProps = {
  name: "service",
  list: "/setting/service",
  show: "/setting/service/show/:id",
  edit: "/setting/service/edit/:id",
  create: "/setting/service/create",
  meta: {
    canDelete: true,
    parent: "setting",
    icon: <BookOutlined />,
  },
};
