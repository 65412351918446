import gql from "graphql-tag";

export const FILES_LIST_QUERY = gql`
  query Files(
    $paging: PagingInput
    $filter: FilterInput
    $sorting: [SorterInput!]
  ) {
    files(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      nodes {
        id
        createdAt
        name
        size
        patient {
          name
        }
      }
      totalCount
    }
  }
`;

export const FILE_GET_LINK_QUERY = gql`
  query File($id: ID!) {
    file(id: $id)
  }
`;

export const FILE_SHOW_QUERY = gql`
  query File($id: ID!) {
    file(id: $id) {
      id
      createdAt
      updatedAt
      name
      key
      size
      url
      patient {
        id
        createdAt
        updatedAt
        name
      }
    }
  }
`;

export const FILE_DELETE_MUTATION = gql`
  mutation DeleteFile($input: DeleteFileInput!) {
    deleteFile(input: $input)
  }
`;

export const FILE_CREATE_MUTATION = gql`
  mutation CreateFile($input: CreateFileInput!) {
    createFile(input: $input) {
      id
      createdAt
      updatedAt
      name
      key
      size
      url
    }
  }
`;
