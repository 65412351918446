import { Edit, useForm } from "@refinedev/antd";
import { Form } from "antd";
import { CanAccess, useTranslate } from "@refinedev/core";
import {
  General,
  Diagnose,
  Treatment,
  Results,
  Positions,
} from "../../components/treatment";
import {
  TREATMENT_EDIT_MUTATION,
  TREATMENT_DELETE_MUTATION,
  TREATMENT_EDIT_QUERY,
} from "../../queries";
import { Unauthorized } from "../../components/common";

export const TreatmentEdit = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, formLoading } = useForm({
    meta: {
      gqlMutation: TREATMENT_EDIT_MUTATION,
      gqlQuery: TREATMENT_EDIT_QUERY,
    },
  });

  return (
    <CanAccess fallback={<Unauthorized />}>
      <Edit
        saveButtonProps={saveButtonProps}
        isLoading={formLoading}
        deleteButtonProps={{ meta: { gqlMutation: TREATMENT_DELETE_MUTATION } }}
        breadcrumb={false}
        title={translate("pages.treatment.titles.edit")}
      >
        <Form {...formProps}>
          <General editable />
          <Diagnose editable style={{ minHeight: "150px" }} />
          <Treatment editable />
          <Results editable />
          <Positions editable />
        </Form>
      </Edit>
    </CanAccess>
  );
};
