import { useModalForm } from "@refinedev/antd";
import { Form, Modal } from "antd";
import { Input, Select } from "antx";
import { USER_CREATE_MUTATION } from "../../queries";
import { useEffect } from "react";
import { useInvalidate, useTranslate } from "@refinedev/core";

type Props = {
  open?: boolean;
  onClose?: () => void;
};

export const UserCreate = ({ open, onClose }: Props) => {
  const translate = useTranslate();
  const invalidate = useInvalidate();
  const { modalProps, show, close, formProps, form } = useModalForm({
    resource: "user",
    action: "create",
    meta: {
      gqlMutation: USER_CREATE_MUTATION,
    },
    onMutationSuccess: () =>
      invalidate({
        resource: "users",
        invalidates: ["list"],
      }),
  });

  useEffect(() => {
    if (open) {
      show();
    } else {
      form.resetFields();
      close();
    }
  }, [open]);

  return (
    <Modal
      {...modalProps}
      title={translate("pages.user.titles.create")}
      onClose={onClose}
      onCancel={onClose}
      style={{ maxWidth: 500 }}
    >
      <Form {...formProps} layout="vertical">
        <Input
          style={{ paddingTop: 30 }}
          name="name"
          label={translate("pages.user.fields.name")}
          rules={[
            {
              required: true,
              message: translate("validations.empty_name"),
            },
          ]}
        />
        <Input
          name="email"
          label={translate("pages.user.fields.email")}
          type="email"
          rules={[
            {
              required: true,
              message: translate("validations.empty_email"),
            },
          ]}
        />
        <Input
          name="password"
          label={translate("pages.user.fields.password")}
          type="password"
          rules={[
            {
              required: true,
              message: translate("validations.empty_password"),
            },
          ]}
        />
        <Select
          name="role"
          label={translate("pages.user.fields.role")}
          rules={[
            {
              required: true,
              message: translate("validations.empty_role"),
            },
          ]}
        >
          <Select.Option value="GUEST">
            {translate("selects.role.guest")}
          </Select.Option>
          <Select.Option value="USER">
            {translate("selects.role.user")}
          </Select.Option>
          <Select.Option value="ADMIN">
            {translate("selects.role.admin")}
          </Select.Option>
        </Select>
      </Form>
    </Modal>
  );
};
