import { BaseRecord, useTranslate } from "@refinedev/core";
import { Col, Divider, Row } from "antd";
import { Completed, Created, Name, Reference, Status, Updated } from ".";

type Props = {
  record?: BaseRecord;
  editable?: boolean;
  create?: boolean;
};

export const General = ({ record, editable, create }: Props) => {
  const translate = useTranslate();

  return (
    <>
      <Divider>{translate("pages.treatment.fields.general")}</Divider>
      {!create && (
        <Row style={{ marginTop: "-10px" }}>
          <Col span={6}>
            <Created createdAt={record?.createdAt} />
          </Col>
          <Col span={8}>
            <Updated updatedAt={record?.updatedAt} />
          </Col>
          <Col span={10}>
            <Reference reference={record?.reference} editable={editable} />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: "-10px" }}>
        <Col span={12}>
          <Name name={record?.patient?.name} editable={editable} />
        </Col>
        <Col span={8}>
          <Status status={record?.status} editable={editable} />
        </Col>
        <Col span={4}>
          <Completed completedOn={record?.completedOn} editable={editable} />
        </Col>
      </Row>
    </>
  );
};
