import { useTranslate } from "@refinedev/core";
import { Typography } from "antd";
import { Watch } from "antx";

type Props = {
  amount?: number;
  tax?: string;
  editable?: boolean;
};

export const Netto = (props: Props) => {
  const translate = useTranslate();

  const calculatePreTax = (value: number, vatTax: string) => {
    let rate = 0;
    switch (vatTax) {
      case "VAT_7":
        rate = 0.07;
        break;
      case "VAT_19":
        rate = 0.19;
        break;
      case "VAT_0":
      default:
        break;
    }

    return (value ?? 0) / (1 + rate);
  };

  return (
    <>
      <Typography.Title level={5} style={{ textAlign: "left" }}>
        {translate("pages.invoice.fields.amount_netto")}
      </Typography.Title>
      {props.editable ? (
        <Watch list={["tax", "amount"]}>
          {([tax, amount]) => (
            <span style={{ alignSelf: "end" }}>
              {calculatePreTax(Number(amount), tax).toFixed(2) + " €"}
            </span>
          )}
        </Watch>
      ) : (
        <span style={{ alignSelf: "end" }}>
          {calculatePreTax(props.amount ?? 0, props.tax ?? "VAT_0").toFixed(2) +
            " €"}
        </span>
      )}
    </>
  );
};
