import { DateField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import dayjs from "dayjs";
import { HeaderText } from "../common";

type Props = {
  updatedAt?: Date;
};

export const Updated = ({ updatedAt }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText title={translate("pages.treatment.fields.updatedAt")}>
      <DateField value={dayjs(updatedAt).locale("de-de")} />
    </HeaderText>
  );
}
