import { useTranslate } from "@refinedev/core";
import { CSSProperties } from "react";
import { TextField } from "@refinedev/antd";
import { TextArea } from "antx";
import { HeaderText } from "../common";

type Props = {
  style?: CSSProperties;
  results?: string;
  editable?: boolean;
};

export const Results = ({ style, results, editable }: Props) => {
  const translate = useTranslate();

  return (
    <HeaderText
      title={translate("pages.treatment.fields.results")}
      style={style}
    >
      {editable ? (
        <TextArea
          name="results"
          autoSize={{ minRows: 6 }}
          style={{ margin: "-5px" }}
        />
      ) : (
        <TextField value={results} style={{ height: "100px" }} />
      )}
    </HeaderText>
  );
};
